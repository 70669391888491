import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {API_URL_PUBLIC, WEB_URL} from "../vars";

/*
Usage:
Simply add these html tags to a website where you want the testimonial to appear

<script type="text/javascript" src="https://testimonial.to/js/iframeResizer.min.js"></script>
<iframe id="testimonialto-carousel-all-bleh-dark" src="http://localhost:3000/embed/avatar/Testimonial.se?text=nöjda kunder" frameborder="0" scrolling="yes" width="100%" height="100%"></iframe>
<script type="text/javascript">iFrameResize({log: false, checkOrigin: false}, "#testimonialto-carousel-all-bleh-dark");</script>

Worth noting about the iframe url: /embed/avatar/Testimonial.se
You can add query params to generate personlized data
text=nöjda kunder // The text to write after the number
intialNum=55, an initial number to start with.
the text is something that you use to easily change the text on the testimonial, if not supplied a default will be shown.

 */

export default function WidgetAvatarPage(props) {
    // initialNumber should be a setting on the board
    // text should be a setting on the board
    const [searchParams, setSearchParams] = useSearchParams();
    const initialNumber = Number(searchParams.get("initialNum")) || props.initialNum || 5;

    const [number, setNumber] = useState(initialNumber)
    const [text, setText] = useState("")
    const { id, fetchAll } = useParams();
    let idParam = id;
    if (!id) {
        idParam = props.id;
    }
    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [avatars, setAvatars] = useState([])
    const location = useLocation();
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    useEffect(() => {
        // 👇 add class to body element
        let url = `${API_URL_PUBLIC}/embed/avatar/${idParam}`
        if (props.fetchAll){
           url += "?all=true"
        }
        fetch(url, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                console.log(jsonResponse)
                if(jsonResponse?.data) {
                    const responseLength = jsonResponse.data.length;
                    if(responseLength < 5) {
                        setNumber(initialNumber);
                    } else {
                        console.log("SETTING NEW NUM", jsonResponse.data.length)
                        setNumber(jsonResponse.data.length);
                    }
                    let data = jsonResponse.data.reverse()
                    if (data.length > 10) {
                        data = data.slice(-10)
                    }
                    setAvatars(data)
                }
                // do whatever you want with the JSON response
                if(!apiError) {
                    setSuccess(true)
                    setApiError(false)
                }
            }).catch((error) => {
            console.log("ERR")
            // Handle the error
            console.log(error);
        });
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if(props.initialText) {
            setText(props.initialText)
        } else {
            setText(`Fler än ${number + avatars.length} nöjda kunder`)
            if(searchParams.get("text1"))
                setText(`${searchParams.get("text1")} ${number + avatars.length} ${searchParams.get("text2")}`)
        }
        // eslint-disable-next-line
    }, [number, avatars, searchParams])

    if (!avatars.length) return (<div style={{height: 216}}></div>)
    return (
        <div>
            <section className="mt-10">
                <div className="mx-auto max-w-6xl ">
                    <div className="mx-auto flex w-full max-w-5xl flex-col items-center space-y-8 text-center lg:px-8 ">
                        <div className="flex flex-col items-center gap-2 md:flex-row align-middle">
                            <div className="flex -space-x-6 ">
                                {avatars.map(item =>
                                        <img
                                            key={item?.img_name}
                                            src={item?.img_base64}
                                            alt="avatar"
                                            className="h-14 w-14 rounded-full md:h-16 md:w-16 border-2 border-white"
                                        />
                                )}
                                <div className="grid h-14 w-14 place-content-center pl-5  font-bold  md:h-16 md:w-16 ">
                                    +{number}
                                </div>
                            </div>
                            <p className="text-lg font-medium">{text}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
