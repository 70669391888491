import React, {useEffect, useState} from 'react';
import "../index.css";
import {API_URL_PUBLIC} from "../vars";
import {Link, useParams} from "react-router-dom";
import FileBase64 from 'react-file-base64';
import {ArrowLeftIcon} from "@heroicons/react/solid";
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-avataaars-sprites';
import { ref, uploadBytesResumable, getDownloadURL, updateMetadata } from "firebase/storage";
import {storage} from '../firebase'
import {trackTestimonialCreateText} from "../tracking";
import {Mixpanel} from "../Mixpanel";



export const TestimonialCaptureText = () => {
    const { board } = useParams();
    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [name, setName] = useState("");
    const [tagline, setTagline] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");
    const [file, setFile] = useState(undefined)
    const [percent, setPercent] = useState(0);
    const [step, setStep] = useState(1);
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    function makeid() {
        let length           = 10
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const generateUUID = () => {
        let
            d = new Date().getTime(),
            d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            // eslint-disable-next-line no-mixed-operators
            return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
    };

    let options = {
        "base64": true,
        "mouth": ["smile", "tongue", "twinkle"],
        "eyes": ["happy", "surprised"],
        "backgroundColor": "#65C9FF"
    };
    let avatars = new Avatars(sprites, options);
    useEffect(() => {
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
        if(defaultAvatar === "") generateRandomAvatar();
    })

    function generateRandomAvatar() {
        setDefaultAvatar(avatars.create());
    }

    function getFile(file){
        setFile(file)
    }

    let downloadUrl
    let fileName = generateUUID();
    const handleSubmitReview = (e) => {
        e.preventDefault()
        if(!file) {
            console.error(`not an image, the image file is a ${typeof(file)}`)
            submitReviewToBackend(e)
        } else {
            let fileType = file.name.match(/\.([^.]+)$/)[1];
            fileName += "." + fileType;
            const storageRef = ref(storage, `/files/${fileName}`)
            // Create file metadata to update
            const newMetadata = {
                customMetadata: {
                    'board': board,
                }
            };
            const uploadTask = uploadBytesResumable(storageRef, file.file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.log("SOME ERROR", err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        downloadUrl = url;
                    });

                    updateMetadata(storageRef, newMetadata)
                        .then((metadata) => {
                            // Updated metadata for file is returned in the Promise
                            submitReviewToBackend(e)
                        }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });
                }
            );
        }
    }

    const submitReviewToBackend = (e) => {
        e.preventDefault()
        let fileToSend = {};
        if(!file) {
            fileToSend.base64 = defaultAvatar
            fileToSend.name = makeid()
            fileToSend.type = "image/svg+xml"
        } else {
            fileToSend.base64 = downloadUrl
            fileToSend.name = fileName
            fileToSend.type = file.type
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                profile_id: board,
                author: name,
                tagline: tagline,
                email: email,
                rating: rating,
                text: message,
                base64: fileToSend.base64,
                name: fileToSend.name,
                type: fileToSend.type,
            })
        };
        fetch(`${API_URL_PUBLIC}/review`, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    trackTestimonialCreateText();
                    Mixpanel.track('Successful Testimonial Text', {'profile': board});
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                // do whatever you want with the JSON response
                if(!apiError) {
                    setSuccess(true)
                    setApiError(false)
                }
            }).catch((error) => {
            // Handle the error
            console.log(error);
        });
    }

    const handleClose = () => {
        setApiError(!apiError);
    };

    const goToStep2 = () => {
        setStep(2);
    }

    if(step === 2) {

    return (
        <>
            {success ? (
                <section className="px-6 mt-20 ">
                    <div className="mt-80 text-center max-w-4xl m-auto">
                        <h2 className="text-4xl font-semibold text-heading md:text-5xl">
                           🙏 Tack!
                        </h2>
                        <p className="mt-10 mx-auto mt-4 max-w-3xl text-base text-text sm:mt-6 sm:text-lg">Vi uppskattar verkligen att du tog dig tid att skriva en recension!</p>
                    </div>
                </section>

            ) : (
                <section className="bg-layer-1 p-6 md:p-40 align-middle">
                    <div className="mx-auto w-full max-w-xl ">
                        <div className="grid grid-cols-1 gap-6 ">
                            <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <h2 className="mt-8 text-3xl font-semibold text-heading md:text-3xl">
                                Nästan klar 🙌
                            </h2>


                                <form className="my-10" onSubmit={handleSubmitReview}>

                                    <div className="mb-6">
                                        <label htmlFor="name" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Ditt namn
                                        </label>
                                        <input type="text" id="name"
                                               className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                               placeholder="Ditt namn"
                                               required={true}
                                               onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="name" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Din roll
                                        </label>
                                        <input type="text" id="name"
                                               className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                               placeholder="Din roll"
                                               required={false}
                                               onChange={(e) => setTagline(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="email" className="block text-left mb-1 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Din mejl
                                        </label>
                                        <p className="block text-left mb-2 text-xs font-small text-gray-900 dark:text-gray-300">
                                            Så att vi kan kontakta dig angående din recension
                                        </p>
                                        <input type="email" id="email"
                                               className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                               placeholder="din@mejl.com" required={true}
                                               onChange={(e) => setEmail(e.target.value)}
                                        />

                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="avatar" className="block text-left mb-3 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            <span onClick={generateRandomAvatar} className="text-primary hover:cursor-pointer">Generera en ny profilbild nedan </span><br/>
                                            eller ännu bättre, ta en ny, eller ladda upp en bild på dig själv. Ta t.ex din profilbild från LinkedIn, Twitter Facebook eller liknande.
                                        </label>

                                        <img
                                            src={file?.base64 || defaultAvatar}
                                            alt="your profile"
                                            className="mr-5 inline-block h-18 w-18 shrink-0 rounded-full"
                                        />

                                        <FileBase64
                                            id="avatar"
                                            multiple={ false }
                                            onDone={ getFile } />

                                    </div>


                                    {apiError ? (
                                        <div
                                            className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
                                            role="alert">
                                            <strong className="font-bold">Ooops! </strong>
                                            <span className="block sm:inline">Vi kunde inte spara din recension,<br/> testa igen om en liten stund.<br/>
                                            <button type="button" onClick={handleClose} className={"text-white font-regular px-5 py-2.5 text-center bg-yellow-600 mt-2"}>Försök igen</button> </span>
                                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}
                                                     viewBox="0 0 20 20"><title>Close</title><path
                                                    d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                        <button type="submit"
                                                className={`w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2  focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}>
                                            {percent > 0 && (
                                                <svg aria-hidden="true"
                                                     className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                     viewBox="0 0 100 101" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"/>
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"/>
                                                </svg>
                                            )}
                                            Skicka in
                                        </button>
                                        <p className="mt-4 block text-sm text-text/50 font-semibold  ">
                                            Jag godkänner att texten och mitt namn kan användas i marknadsföring. Du kan ta tillbaka ditt samtycke genom att kontakta oss.
                                        </p>
                                        </>
                                    )}
                                </form>
                        </div>
                        </div>
                    </div>
            </section>
            )}
            </>
    );
    } else {
        return (

            <section className="bg-layer-1 p-6 md:p-40 align-middle">
                <div className="mx-auto w-full max-w-xl ">
                    <div className="grid grid-cols-1 gap-6 ">
                        <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <span className={"float-right"}>
                                <Link to={`/${board}`}
                                      className="inline-flex aspect-square cursor-pointer items-center justify-center rounded-full border-none border-primary bg-secondary/30 p-2.5 font-semibold text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent/70 focus:outline-none focus:ring-2  focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white child-svg:h-6 child-svg:w-6 dark:focus:ring-white/80"
                                >
                                    <ArrowLeftIcon className="h-6 w-6" />
                                </Link>
                            </span>
                            <h3 className="mt-4 text-2xl font-semibold text-heading">
                                Skriv något om oss
                            </h3>
                            <p className="mt-4 text-lg text-text">
                                T.ex:

                            </p>
                            <ul className="ml-5 list-disc">
                                <li>Vad gillar du om oss?</li>
                                <li>Skulle du rekommendera oss till en vän?</li>
                            </ul>
                            <div className="star-rating mt-5">
                                {[...Array(5)].map((star, index) => {
                                    index += 1;
                                    return (
                                        <button
                                            type="button"
                                            key={index}
                                            className={index <= (hover || rating) ? "on " : "off"}
                                            onClick={() => setRating(index)}
                                            onMouseEnter={() => setHover(index)}
                                            onMouseLeave={() => setHover(rating)}
                                        >
                                            <span className="star text-2xl">&#9733;</span>
                                        </button>
                                    );
                                })}
                            </div>
                            <textarea id="message" rows="5"
                                      required={true}
                                      className="mt-2 block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                      placeholder="Skriv något om oss ✨"
                                      onChange={(e) => setMessage(e.target.value)}
                            />
                            <button disabled={!message.length}
                                    onClick={goToStep2}
                                    className={`mt-5 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}>
                                👉 Skicka in
                            </button>

                        </div>
                    </div>
                </div>
            </section>


        );
    }
}
