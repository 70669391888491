import React, {useCallback, useState, setState, useEffect} from "react";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import {Link, useNavigate} from 'react-router-dom';

import {app, auth, storage} from '../../firebase'
import {URL_DASHBOARD} from "../../constants";
import {trackSignup} from "../../tracking";
import {Mixpanel} from "../../Mixpanel";

export default function Signup() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
    }, []);

    const logInWithEmailAndPassword = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log("LOGGED IN", user)
                Mixpanel.identify(user.uid)
                Mixpanel.track("Successful login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert("Sorry, could not log in: " + error.code)
            });
    };

    useEffect( () => {
        console.log("CHANGED", password, email)

    }, [password, email])

    useEffect(() => {
        if (loading) return;
        if (user) navigate(URL_DASHBOARD);
    }, [user, loading, navigate]);

    const registerWithEmailAndPassword = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log("USER ", user)
                trackSignup();
                Mixpanel.identify(user.uid)
                Mixpanel.track("Successful signup")
                Mixpanel.people.set({"$email": user.email})
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                if(errorCode === "auth/email-already-in-use") {
                    logInWithEmailAndPassword();
                } else {
                    alert("Sorry, could not register user: " + error.code)
                }
            });
    };


    return (
        <>
            <section className="bg-layer-1 p-6 ">
                <div className="mx-auto w-full max-w-xl ">
                    <div className="flex flex-col justify-center pt-10 pb-14 sm:px-6 lg:px-8">
                        <div className="flex flex-col justify-center sm:mx-auto sm:w-full sm:max-w-md">
                            {/* Logo */}
                            <div className="mx-auto h-9 text-heading">
                                <p className={"text-heading font-black"}>Kundreferens.</p>
                            </div>

                            <p className="mt-6 text-center text-sm text-text">
                                Har du redan ett konto?{" "}
                                <Link to="/auth/login"
                                    className="font-semibold text-primary hover:text-primary-accent"
                                >
                                   Logga in här
                                </Link>
                            </p>
                        </div>

                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                            <div className="bg-layer-2 py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                <h1 className="text-center text-3xl font-semibold text-heading">
                                    Registrera ett nytt konto
                                </h1>

                                <div className="mt-6 flex flex-col space-y-4">
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-semibold text-heading"
                                        >
                                            Email
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-semibold text-heading"
                                        >
                                            Ditt lösenord
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                        />
                                    </div>

                                    <div className="flex justify-end">
                                        <Link to="/auth/reset"
                                            className="text-sm font-semibold text-primary hover:text-primary-accent"
                                        >
                                            Glömt ditt lösenord?
                                        </Link>
                                    </div>

                                    <button
                                        type="submit"
                                        className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-green-500 bg-green-500 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 hover:bg-green-600 focus:outline-none focus:ring-2 focus:bg-green-600 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80 	"
                                        onClick={registerWithEmailAndPassword}
                                    >
                                        Skapa nytt konto
                                    </button>
                                    {/*
                                    <button
                                        type="button"
                                        className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 text-sm font-semibold  text-text shadow-sm hover:text-heading focus:text-heading focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:text-text dark:focus:ring-white/80"
                                    >
                                        <svg viewBox="0 0 48 48" className="mr-2 h-5 w-5">
                                            <path
                                                fill="#FFC107"
                                                d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24s8.955 20 20 20s20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
                                            />
                                            <path
                                                fill="#FF3D00"
                                                d="m6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C16.318 4 9.656 8.337 6.306 14.691z"
                                            />
                                            <path
                                                fill="#4CAF50"
                                                d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"
                                            />
                                            <path
                                                fill="#1976D2"
                                                d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002l6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"
                                            />
                                        </svg>
                                        Sign in with Google
                                    </button>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       </>
    );
}
