import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";

export default function FAQ() {
    return (
        <div className="flex w-96 flex-col space-y-2 text-left">
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="text-left relative flex w-full items-center justify-between rounded-lg border border-muted-1 bg-layer-2 px-4 py-2 text-lg font-semibold text-heading hover:bg-muted-1 focus:z-10 focus:outline-none focus:ring-2 focus:ring-heading/80 dark:border-0 dark:bg-layer-3">
                            Vad kommer det kosta?
                            <ChevronUpIcon
                                className={`${
                                    open ? "rotate-180 text-heading" : "text-text"
                                } h-6 w-6`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 py-2 ">
                          Vi har inte bestämt priserna än men det kommer alltid finnas en gratisplan där du kan använda delar av tjänsten utan kostnad.
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="text-left relative flex w-full items-center justify-between rounded-lg border border-muted-1 bg-layer-2 px-4 py-2 text-lg font-semibold text-heading hover:bg-muted-1 focus:z-10 focus:outline-none focus:ring-2 focus:ring-heading/80 dark:border-0 dark:bg-layer-3">
                            Hur gör jag för att få in nya videotestimonials?
                            <ChevronUpIcon
                                className={`${
                                    open ? "rotate-180 text-heading" : "text-text"
                                } h-6 w-6`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 py-2">
                            Det finns många olika sätt, de enklaste är att du tar din unika testimonials länk och delar den med dina befintliga kunder via mail, sms, QR kod eller liknande.
                            Om du samtidigt ger dina kunder ett incitament, t.ex en värdecheck så brukar ett antal kunder ta sig tid och dela med sig av en video testimonial.
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="text-left relative flex w-full items-center justify-between rounded-lg border border-muted-1 bg-layer-2 px-4 py-2 text-lg font-semibold text-heading hover:bg-muted-1 focus:z-10 focus:outline-none focus:ring-2 focus:ring-heading/80 dark:border-0 dark:bg-layer-3">
                            Hur lång tid tar det att spela in en video testimonial?
                            <ChevronUpIcon
                                className={`${
                                    open ? "rotate-180 text-heading" : "text-text"
                                } h-6 w-6`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 py-2">
                            Vi brukar uppskatta att det tar mellan 3-10 minuter för en kund att spela in en testimonial, det som oftast tar lite tid är att kunden vill spela in sitt klipp flera gånger. Här är det möjligt för er att ge några förslag på frågor som kunden kan besvara i sin film för att göra processen smidigare.
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="text-left relative flex w-full items-center justify-between rounded-lg border border-muted-1 bg-layer-2 px-4 py-2 text-lg font-semibold text-heading hover:bg-muted-1 focus:z-10 focus:outline-none focus:ring-2 focus:ring-heading/80 dark:border-0 dark:bg-layer-3">
                            Lägger ni in reklam i videoklippen?
                            <ChevronUpIcon
                                className={`${
                                    open ? "rotate-180 text-heading" : "text-text"
                                } h-6 w-6`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 py-2">
                            <b>Självklart inte!</b> Vi vill att dina video testimonials ska framhäva dina kunder och din tjänst eller produkt, inte irrelevant reklam om andra saker.
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="text-left relative flex w-full items-center justify-between rounded-lg border border-muted-1 bg-layer-2 px-4 py-2 text-lg font-semibold text-heading hover:bg-muted-1 focus:z-10 focus:outline-none focus:ring-2 focus:ring-heading/80 dark:border-0 dark:bg-layer-3">
                            Och det här med GDPR?
                            <ChevronUpIcon
                                className={`${
                                    open ? "rotate-180 text-heading" : "text-text"
                                } h-6 w-6`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 py-2">
                            Din och dina kunders rätt till personlig integritet är något som vi tar på största allvar, därför lagras och behandlas alla videofilmer och annan kundinformation på servrar inom den Europeiska Unionen (EU).
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="text-left relative flex w-full items-center justify-between rounded-lg border border-muted-1 bg-layer-2 px-4 py-2 text-lg font-semibold text-heading hover:bg-muted-1 focus:z-10 focus:outline-none focus:ring-2 focus:ring-heading/80 dark:border-0 dark:bg-layer-3">
                            Hur gör jag för att implementera tjänsten?
                            <ChevronUpIcon
                                className={`${
                                    open ? "rotate-180 text-heading" : "text-text"
                                } h-6 w-6`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 py-2">
                            När du har skapat ett konto så får du automatiskt en unik sida där dina kunder enkelt kan spela in video testimonials, men som ett första steg så är det bra att du först spelar in en personlig video där du presenterar dina tjänster och ditt företag och lägga in på din hemsida.
                            Att lägga in en video på din hemsida är väldigt enkelt och fungerar på alla hemsidor, t.ex wordpress, wix, quickbutik Shopify, Woocommerce osv, du kopierar en liten kodsnutt och lägga in på den plats på din hemsida där du vill att videon ska visas - och sen är det klart!
                            Du kan alltid använda dig av vårt kom igång paket där vi hjälper dig med det tekniska men även skapar en strategi och förslag till kommunikation som du kan använda för att få in recensioner från befintliga kunder.
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
}
