import {URL_SIGNUP} from "../constants";
import {Link} from "react-router-dom";

export default function Hero() {
    return (
        <section className="py-12 px-8 md:py-36 xl:px-0">
            <div className="mx-auto max-w-6xl space-y-8 md:space-y-16">
                <div className="mx-auto flex w-full max-w-5xl flex-col items-center space-y-8 text-center lg:px-8">
                    <h1 className="text-4xl font-semibold text-heading md:text-6xl">
            <span className="text-muted2">
              Låt dina kunder rekommendera din verksamhet
            </span>{" "}
                <br />
                genom video och text testimonials.


                    </h1>
                    <p className="text-lg font-medium md:max-w-3xl">
                        Att samla in kundrecensioner är väldigt svårt - men det behöver inte vara det! På bara några minuter så kan du samla in text och videorekommendationer från dina kunder och visa dem på din hemsida.
                    </p>

                    <Link to={URL_SIGNUP}
                       rel="noreferrer"
                        type="button"
                        className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none bg-gradient-to-r from-fuchsia-600 to-indigo-600 px-4 py-2.5 text-base font-semibold text-white transition duration-200 hover:bg-gradient-to-r hover:from-fuchsia-600 hover:to-indigo-500 focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:opacity-30 disabled:hover:text-white dark:focus:ring-white/80"
                    >
                        Testa gratis
                    </Link>
                    <div className="flex flex-col items-center gap-2 md:flex-row">
                        <div className="flex -space-x-4">
                            <img
                                src="/assets/avatars/randy-warren.png"
                                alt="avatar"
                                className="h-14 w-14 rounded-full md:h-16 md:w-16"
                            />
                            <img
                                src="/assets/avatars/irina-zaytesev.png"
                                alt="avatar"
                                className="h-14 w-14 rounded-full md:h-16 md:w-16"
                            />
                            <img
                                src="/assets/avatars/pasquale-blanco.png"
                                alt="avatar"
                                className="h-14 w-14 rounded-full md:h-16 md:w-16"
                            />
                            <img
                                src="/assets/avatars/nicholas-turner.png"
                                alt="avatar"
                                className="h-14 w-14 rounded-full md:h-16 md:w-16"
                            />
                            <img
                                src="/assets/avatars/dianne-russell.png"
                                alt="avatar"
                                className="h-14 w-14 rounded-full md:h-16 md:w-16"
                            />
                            <div className="grid h-14 w-14 place-content-center rounded-full bg-layer-2 font-medium dark:bg-layer-3 md:h-16 md:w-16">
                                +10
                            </div>
                        </div>
                        <p className="text-lg font-medium">Fler än 15 kunder på väntlistan</p>
                    </div>
                    <p>En sån här ☝️ "widget" kan du enkelt lägga in på din hemsida!</p>
                </div>

                {/*<div>
                    <img
                        src="/assets/mobile-app-dashboard.jpeg"
                        className="w-full rounded-3xl"
                    />
                </div>
                */}
            </div>
        </section>
    );
}
