import Avatars from "@dicebear/avatars";
import sprites from "@dicebear/avatars-avataaars-sprites";

export const generateUUID = () => {
    let
        d = new Date().getTime(),
        d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        // eslint-disable-next-line no-mixed-operators
        return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
};

export const makeid = () => {
    let length           = 10
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


const getRandomAvatar = () => {
    const avatarOptions = {
        "base64": true,
        "mouth": ["smile", "tongue", "twinkle"],
        "eyes": ["happy", "surprised"],
        "backgroundColor": "#65C9FF"
    };
    const avatars = new Avatars(sprites, avatarOptions);
    const randomAvatar = avatars.create();
    return randomAvatar;
};

export default getRandomAvatar;
