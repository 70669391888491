import { ArrowRightIcon, LightningBoltIcon } from "@heroicons/react/outline";
import {Link} from "react-router-dom";

export default function TryIt() {
    return (
        <section className="p-8 md:py-20">
            <div className="rounded-4xl mx-auto flex max-w-6xl flex-col items-center text-center sm:bg-layer-2 sm:py-12 sm:px-6 md:py-18 lg:px-32 2xl:px-64">
        <span className="inline-flex items-center rounded-full border-2 border-pink-200 bg-pink-200 px-2 py-1 text-sm font-semibold text-pink-600 shadow-sm">
          <LightningBoltIcon className="mr-1 h-5 w-5" />
          Hur ser det ut?
        </span>

                <h2 className="mt-6 text-3xl font-semibold text-heading md:text-4xl">
                    ⭐️Testa själv ⭐️
                </h2>

                <p className="mt-4 text-lg font-medium">
                    Vill du veta hur det ser ut när man lämnar en recension?
                </p>

                <Link to="/d8feea00-af9e-443f-abfb-b06101c9e88c"
                      className="mt-12 flex items-center space-x-4 text-xl font-medium md:text-2xl">
                     <span className="bg-gradient-to-r text-gradient from-fuchsia-600 to-indigo-600">
                      Testa att lämna en recension här
                    </span>
                    <ArrowRightIcon className="h-6 w-6 text-[#4f46e5] dark:text-[#fda4af]" />
                </Link>
            </div>
        </section>
    );
}
