import React, {useEffect, useState} from "react";
import WidgetWallPage from "../frame_widget_wall";
import SyntaxHighlighter from "react-syntax-highlighter";
import {docco} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {Mixpanel} from "../../Mixpanel";
import {useLocation} from "react-router-dom";
import {WEB_URL} from "../../vars";


export const WallOfLoveBlock = (props) => {
    const {id} = props;
    const codeString = "<div class=\"testimonial-frame-wall\" data-id=\""+id+"\" ></div>\n" +
        "<script defer type=\"text/javascript\" src=\""+WEB_URL+"/embedWall.min.js\"></script>"
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        Mixpanel.track('Viewed Wall of love');
    }, [location]);

    return (
        <main className="mx-auto max-w-content-xl">
            <header className="mt-6 flex flex-col space-y-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
                <h1 className="text-3xl font-semibold text-heading">
                    Din wall of ❤️
                </h1>
            </header>

            <div className="mt-4 flex flex-col space-y-6 md:mt-5 xl:grid xl:grid-cols-2 xl:gap-4 xl:space-y-0">

                <div className="col-span-2 rounded-xl border-muted-1 border-2 px-8 py-6 bg-white">
                    <div className="flex ">
                        <p className="text-lg font-semibold text-heading ">
                            En "wall of love" kan du använda för att visa en eller flera testimonials på din hemsida.
                            Vanligtvis så brukar man lägga den som en undersida, t.ex som man brukar göra med "kontakta oss".
                            Kundrecensioner kommer automatiskt dyka upp på din hemsida varje gång du publicerar en recension via dashboarden.
                        </p>
                    </div>
                    <div className="mt-6 overflow-x-auto scrollbar">
                        <WidgetWallPage id={id} />
                    </div>
                    <div className="mt-16 overflow-x-auto scrollbar">
                        <p>Kopiera och klistra in denna kodsnutt på din hemsida där du vill att widgeten ska synas.</p>
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {codeString}
                        </SyntaxHighlighter>

                    </div>
                </div>

            </div>

        </main>
    )

}
