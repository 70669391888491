import React, {Fragment, useState, useEffect} from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
    HomeIcon,
    LogoutIcon,
    MenuAlt1Icon,
    XIcon,
    InboxInIcon,
    ShareIcon,
    EmojiHappyIcon,
    ViewGridIcon,
    CollectionIcon, StarIcon,ViewBoardsIcon,
} from "@heroicons/react/outline";
import {TestimonialCaptureManual} from "./capture";
import {TestimonialBlock} from "./testimonials";
import {HeartIcon} from "@heroicons/react/outline";
import {WallOfLoveBlock} from "./wall_of_love";
import {UserProofBlock} from "./user_proof";
import {useAuthState} from "react-firebase-hooks/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {URL_LOGIN} from "../../constants";
import {app, auth, storage} from '../../firebase'
import {API_URL} from "../../vars";
import {OnboardingBlock} from "./onboarding";
import {signInWithEmailAndPassword, signOut} from "firebase/auth";
import {QuestionMarkCircleIcon} from "@heroicons/react/outline";
import {ContactBlock} from "./contact";
import {Mixpanel} from "../../Mixpanel";
import {ShareForm, ShareFormBlock} from "./shareForm";
import {PanelBlock} from "./user_panel";
import {PanelAnimatedBlock} from "./user_panel_animated";

const links = [
    { label: "Kundreferenser", icon: EmojiHappyIcon, href: "#", isActive: true },
    { label: "Dina Formulär", icon: StarIcon, href: "#", isActive: true },
    { label: "Widget #1", icon: CollectionIcon, href: "#", isActive: false },
    { label: "Widget #2", icon: ViewBoardsIcon, href: "#", isActive: false },
    { label: "Widget #3", icon: ViewGridIcon, href: "#", isActive: false },
    { label: "Widget #4", icon: HeartIcon, href: "#", isActive: false },
    { label: "Saknar du något", icon: QuestionMarkCircleIcon, href: "#", isActive: false },
];

const bottomLinks = [
    /*{
        label: "Settings",
        icon: CogIcon,
        href: "#",
        isActive: false,
        notificationCount: 0,
    },*/
    { label: "Logout", icon: LogoutIcon, href: "#", isActive: false },
];

export default function DashboardPage() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeLink, setActiveLink] = useState(0);
    const [profile, setProfile] = useState(undefined);
    const [user, loading, error] = useAuthState(auth);
    const [apiLoading, setApiLoading] = useState(true)

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        Mixpanel.track('Viewed Dashboard');
    }, [location]);

    const getProfile = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user?.accessToken}`},
        };
        fetch(`${API_URL}/profile`, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                console.log("profile: ", jsonResponse)
                if(jsonResponse?.data) {
                    setProfile(jsonResponse?.data)
                }
                setApiLoading(false);
            }).catch((error) => {
            console.log("ERR", error)
            // Handle the error
            //alert("Failed to get profile, please refresh the page");
        });
    }

    useEffect(() => {
        if (loading) return;
        console.log("user", user)
        if (!user) return navigate(URL_LOGIN);
        getProfile();
        // eslint-disable-next-line
    }, [user, loading, navigate]);
    const toggleLink = (idx) => {
        links.forEach((link, i) => {
            if (idx === i) {
                link.isActive = true
            } else {
                link.isActive = false
            }
        })
        setActiveLink(idx)
    }
    const doBottomLinks = (idx) => {
        bottomLinks.forEach((link, i) => {
            if (idx === i) {
                link.isActive = true
            } else {
                link.isActive = false
            }
        })
        setActiveLink(idx)
    }

    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
    }, []);


    function closeSidebar() {
        setIsSidebarOpen(false);
    }

    function openSidebar() {
        setIsSidebarOpen(true);
    }

    const doLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    };


    const sidebarElement = (
        <div className="relative z-30 flex h-full flex-col bg-layer-2 shadow">
            <div className="flex flex-1 flex-col overflow-y-auto">
                {/* Logo */}
                <div className="mt-5 h-8 flex-shrink-0 px-4 text-heading">
                    <p className={"text-heading font-black"}>Kundreferens.se</p>

                </div>
                <div className="mt-5 space-y-1 px-1 sm:px-2">
                    {links.map((link, idx) => {
                        const Icon = link.icon;
                        return (
                            <a
                                key={link.label}
                                href={link.href}
                                onClick={() => toggleLink(idx)}
                                className={`${
                                    link.isActive
                                        ? "bg-layer-3 text-heading"
                                        : "text-text hover:bg-layer-3 hover:text-heading"
                                } group relative flex items-center rounded-xl px-2 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-heading/80`}
                            >
                                <Icon className="mr-3 h-6 w-6 flex-shrink-0" />
                                <span className="flex-1 font-semibold">{link.label}</span>
                                {link.notificationCount ? (
                                    <span className="ml-3 inline-block rounded-lg bg-primary py-0.5 px-2 text-sm font-semibold text-white">
                    {link.notificationCount}
                  </span>
                                ) : null}
                            </a>
                        );
                    })}
                </div>
            </div>
            <div className="mb-2 space-y-1 px-1 sm:px-2">
                <a
                    href={"#"}
                    className={"text-text hover:bg-layer-3 hover:text-heading group relative flex items-center rounded-xl px-2 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-heading/80"}
                    onClick={doLogout}
                >
                    <LogoutIcon className="mr-3 h-6 w-6 flex-shrink-0" />
                    <span className="flex-1 font-semibold">Logout</span>
                </a>
            </div>
        </div>
    );

    const navbarElement = (
        <nav className="relative z-20 flex h-16 flex-1 shrink-0 items-center space-x-2 bg-layer-2 px-4 shadow sm:px-6">
            <div className="flex items-center space-x-2 md:hidden">
                <button
                    type="button"
                    onClick={openSidebar}
                    className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2.5 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text"
                >
                    <MenuAlt1Icon className="h-6 w-6" />
                </button>
                {/* Logo */}
                <div className="h-7 text-heading">
                    <p className={"text-heading font-black"}>Kundreferens.se</p>
                </div>
            </div>
        </nav>
    );

    if (apiLoading) {
        return (
            <div className="flex flex-1 items-center justify-center">
                <div className="flex w-full max-w-2xl flex-col items-center px-6 text-center md:px-10">
                    <h1 className="mt-16 text-4xl font-bold text-heading">loading...</h1>
                </div>
            </div>
        )
    }

    return (
        <div className="flex h-screen w-screen">
            {profile?.id && (
                <div className="hidden w-64 flex-shrink-0 md:block">{sidebarElement}</div>
            )}
            <div className="relative w-full flex-1 md:overflow-hidden">
                {profile?.id && (
                    navbarElement
                )}
                <div className="md:absolute md:inset-x-0 md:top-16 md:bottom-0">
                    {profile?.name ? (
                    <div className="h-full py-8 px-6 scrollbar md:overflow-y-auto md:px-8">
                        {activeLink === 0 && (
                            <TestimonialBlock id={profile?.id} profile={profile}/>
                        )}
                        {activeLink === 5 && (
                            <UserProofBlock id={profile?.id}/>
                        )}
                        {activeLink === 2 && (
                            <PanelBlock id={profile?.id}/>
                        )}
                        {activeLink === 3 && (
                            <PanelAnimatedBlock id={profile?.id}/>
                        )}
                        {activeLink === 4 && (
                            <WallOfLoveBlock id={profile?.id}/>
                        )}
                        {activeLink === 1 && (
                            <ShareFormBlock id={profile?.id} profile={profile}/>
                        )}
                        {activeLink === 6 && (
                            <ContactBlock id={profile?.id}/>
                        )}
                    </div>
                    ) : (
                        <div className="h-full py-8 px-6 scrollbar md:overflow-y-auto md:px-8">
                             <OnboardingBlock id={profile?.id} setProfile={setProfile}/>
                        </div>

                    )}
                </div>
            </div>

            {/* Mobile Sidebar */}
            {profile?.name && (
            <Transition appear show={isSidebarOpen} as={Fragment}>
                <Dialog as="div" onClose={closeSidebar}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-layer-1/60 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex h-full w-screen max-w-xs flex-col bg-layer-2 shadow-2xl">
                                <div className="absolute -right-14 top-0 flex w-14 justify-center pt-2">
                                    <button
                                        type="button"
                                        onClick={closeSidebar}
                                        className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text"
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-5 w-5" />
                                    </button>
                                </div>
                                {sidebarElement}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            )}
        </div>
    );


}
