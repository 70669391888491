import React, {useEffect, useState} from "react";
import {API_URL, WEB_URL} from "../../vars";
import {auth} from '../../firebase'
import {useAuthState} from "react-firebase-hooks/auth";
import {trackTestimonialPublish} from "../../tracking";
import {Mixpanel} from "../../Mixpanel";

export const TestimonialBlock = (props) => {
    const {id, profile} = props;
    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [testimonials, setTestimonials] = useState([])
    const [number, setNumber] = useState(0)
    const defaultAvatar = "https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShaggyMullet&accessoriesType=Sunglasses&hairColor=Blonde&facialHairType=Blank&clotheType=Hoodie&clotheColor=Gray02&eyeType=Default&eyebrowType=SadConcerned&mouthType=Smile&skinColor=Tanned"
    const [user, loading, error] = useAuthState(auth);
    const [apiLoading, setApiLoading] = useState(false);

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user?.accessToken}`},
    };

    const nameURLSafe = encodeURI(profile?.name.trim()).toString()
    const captureTestimonialURL = WEB_URL + "/write-ai/" + id + "?name=" + nameURLSafe;

    const getTestimonials = () => {
        setApiLoading(true);
        fetch(`${API_URL}/embed/avatar/${id}?limit=all&all=true`, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                console.log("testimonials: ", jsonResponse)
                if(jsonResponse?.data) {
                    setNumber(jsonResponse.data.length);

                    setTestimonials(jsonResponse.data)
                }
                // do whatever you want with the JSON response
                if(!apiError) {
                    setApiError(false)
                }
            }).catch((error) => {
                console.log("ERR", error)
            }).finally(() => {
            setApiLoading(false);
        });
    }

    const updateTestimonial = (testimonial_id, status) => {
        const data = {
            profile_id: id,
            testimonial_id: testimonial_id,
            status: status
        }
        const params = {method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user?.accessToken}`},
            body: JSON.stringify(data)
        }
        fetch(`${API_URL}/testimonial/publish`, params )
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    trackTestimonialPublish();
                    Mixpanel.track("Changed Testimonial Status", {'profile': id, 'status': status})
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                getTestimonials()
                // do whatever you want with the JSON response
                if(!apiError) {
                    setApiError(false)
                }
            }).catch((error) => {
                console.log("ERR", error)
            });

    }

    useEffect(() => {
        if(!user?.accessToken) return;
        getTestimonials();
        // eslint-disable-next-line
    }, [user]);


    const toggleActive = (testimonial_id, status) => {

        if (status) {
            updateTestimonial(testimonial_id, false)
        } else {
            updateTestimonial(testimonial_id, true)
        }
    }


    return (
        <main className="mx-auto max-w-content-xl">
            <header className="mt-6 flex flex-col space-y-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
                <h1 className="text-3xl font-semibold text-heading">
                    Dina kundreferenser
                </h1>
            </header>

            <div className="mt-4 flex flex-col space-y-6 md:mt-5 xl:grid xl:grid-cols-2 xl:gap-4 xl:space-y-0">

                <div className="col-span-2 rounded-xl bg-layer-2 px-8 py-6">

                    {/*
                    <div className="flex items-center justify-between">
                        <div>
                            <label
                                htmlFor="search"
                                className="sr-only block text-sm font-semibold text-heading"
                            >
                                Search
                            </label>
                            <div className="relative flex">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex flex-shrink-0 items-center pl-4 focus-within:z-20">
                                    <SearchIcon className="h-5 w-5 text-text" />
                                </div>
                                <input
                                    id="search"
                                    name="search"
                                    placeholder="Quick Search by name or email"
                                    className="block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 pl-11 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    */}

                    <div className="mt-6 overflow-x-auto scrollbar">
                        <table className="w-full ">
                            <thead className="text-xs font-semibold uppercase text-text">

                            <tr>
                                <th className="whitespace-nowrap bg-layer-2 py-3 px-4 text-left font-semibold text-text">
                                    Customer
                                </th>
                                <th className="whitespace-nowrap bg-layer-2 py-3 px-4 text-left font-semibold text-text">
                                    Name
                                </th>
                                <th className="whitespace-nowrap bg-layer-2 py-3 px-4 text-left font-semibold text-text">
                                    Testimonial
                                </th>
                                <th className="whitespace-nowrap bg-layer-2 py-3 px-4 text-left font-semibold text-text">
                                    Date
                                </th>
                                <th className="whitespace-nowrap bg-layer-2 py-3 px-4 text-left font-semibold text-text">
                                    Status
                                </th>
                            </tr>
                            </thead>
                            <tbody className="text-sm font-medium">
                            {apiLoading && (
                                <tr >
                                    <td>
                                        <svg aria-hidden="true"
                                             className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                             viewBox="0 0 100 101" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"/>
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"/>
                                        </svg>
                                    </td>
                                </tr>
                            )}
                            {testimonials.length && testimonials?.map((user, index) => (
                                <tr key={index}>
                                    <td
                                        className={"bg-layer-2 whitespace-nowrap py-3 px-4 text-heading"}
                                    >
                                        <img
                                            className="h-16 w-16 shrink-0 rounded-full"
                                            src={user.img_base64 || defaultAvatar}
                                            alt={`${user.author}'s avatar`}
                                        />
                                    </td>

                                    <td
                                        className={"bg-layer-2 whitespace-nowrap py-3 px-4 text-heading"}
                                    >
                                        <h4 className="text-base font-semibold text-heading">
                                            {user.author}
                                        </h4>
                                        <span className="text-sm font-medium text-text/50">
                                                          {user.tagline}
                                                        </span>
                                    </td>
                                    <td
                                        className={"bg-layer-2  py-3 px-4 text-heading"}
                                    >
                                        <ul className="flex justify-left pb-3">
                                            {[
                                                ...Array(user.rating),
                                            ].map((idx) => (
                                                <li key={idx}>
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.04893 3.92756C9.34828 3.00624 10.6517 3.00624 10.951 3.92756L12.0206 7.21935C12.1545 7.63138 12.5384 7.91034 12.9717 7.91034H16.4329C17.4016 7.91034 17.8044 9.14995 17.0207 9.71935L14.2205 11.7538C13.87 12.0084 13.7233 12.4598 13.8572 12.8718L14.9268 16.1636C15.2261 17.0849 14.1717 17.8511 13.3879 17.2817L10.5878 15.2472C10.2373 14.9926 9.76269 14.9926 9.4122 15.2472L6.61203 17.2817C5.82832 17.8511 4.77384 17.0849 5.07319 16.1636L6.14276 12.8718C6.27663 12.4598 6.12997 12.0084 5.77949 11.7538L2.97932 9.71935C2.1956 9.14995 2.59838 7.91034 3.5671 7.91034H7.0283C7.46153 7.91034 7.84548 7.63138 7.97936 7.21935L9.04893 3.92756Z"
                                                            fill="#FB923C"
                                                        />
                                                    </svg>
                                                </li>
                                            ))
                                            }

                                        </ul>
                                        {user.video_url ? (
                                            <div className={"px-1 "}>
                                                <video onClick={e => e.target.play()}
                                                       onMouseEnter={e => e.target.play()}
                                                       onMouseLeave={e => e.target.pause()}
                                                    className="rounded-lg"
                                                       width="120" height="140">
                                                    <source src={user.video_url} type="video/mp4" />
                                                    Sorry, your browser doesn't support videos.
                                                </video>
                                            </div>
                                        ) : (
                                            <>
                                                <p className="mt-2 text-sm font-bold text-text break-normal">
                                                    {user.quote}
                                                </p>
                                                <p className="mt-2 text-sm font-medium text-text break-normal">
                                                    {user.text}
                                                </p>
                                            </>
                                        )}
                                    </td>

                                    <td
                                        className={"bg-layer-2 whitespace-nowrap py-3 px-4 text-heading"}
                                    >
                                        {user.created_at.replace('T', ' ').slice(0,-4)}
                                    </td>
                                    <td
                                        className={"bg-layer-2 whitespace-nowrap py-3 px-4 text-heading cursor-pointer"}
                                        onClick={e => toggleActive(user.id, user.published_at)}
                                    >
                                        {user.published_at ? (

                                                        <span className="inline-flex items-center rounded-full border-2 border-muted-1 bg-muted-1 px-2 py-1 text-base font-semibold text-heading shadow-sm">
                                                        <svg
                                                            fill="currentColor"
                                                            viewBox="0 0 8 8"
                                                            className="mr-1 h-2.5 w-2.5 text-green-500"
                                                        >
                                                          <circle cx={4} cy={4} r={3} />
                                                        </svg>
                                                        Aktiv
                                                      </span>
                                        ) : (
                                            <span className="inline-flex items-center rounded-full border-2 border-muted-1 bg-muted-1 px-2 py-1 text-base font-semibold text-heading shadow-sm">
                                                        <svg
                                                            fill="currentColor"
                                                            viewBox="0 0 8 8"
                                                            className="mr-1 h-2.5 w-2.5 text-red-500"
                                                        >
                                                          <circle cx={4} cy={4} r={3} />
                                                        </svg>
                                                        Inaktiv
                                                      </span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    {/*
                   <div className="mt-2 flex justify-center">
                        <button
                            type="button"
                            className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-transparent bg-transparent px-3 py-2 text-xs font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text"
                        >
                            View all records
                        </button>
                    </div>
                    */}
                </div>
            </div>
        </main>
    )

}
