import React, {useEffect} from 'react';
import {
    Link, useParams
} from "react-router-dom";

import "../index.css";
import {LightBulbIcon} from "@heroicons/react/outline";
import {Mixpanel} from "../Mixpanel";


export const TestimonialStart = () => {
    const { board } = useParams();
    useEffect(() => {
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
        Mixpanel.track('Start Testimonial', {'profile': board});
    }, [board]);


    return (
        <section className="bg-layer-1 p-6 md:p-40 align-middle">
            <div className="mx-auto w-full max-w-xl ">
                <div className="grid grid-cols-1 gap-6 ">
                    <div className="rounded-xl bg-layer-2 p-5 md:px-8 md:py-6 border-solid border-muted-2 border-2">
                        <h3 className="mt-4 text-2xl font-semibold text-heading">
                            Lämna en recension
                        </h3>
                        <p className="mt-4 text-lg text-text">
                            Vi vill jättegärna höra om din upplevelse!
                        </p>
                            <div className="flex justify-center mt-6 mb-4" >
                                <Link to={`/record/${board}`}
                                      className=" w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2  focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80">
                                    📹 Spela in en video
                                </Link>
                            </div>
                            <div className="flex justify-center" >
                                <Link to={`/write-ai/${board}`}
                                      className="w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-secondary bg-secondary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent focus:outline-none focus:ring-2 focus:ring-primary/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-secondary disabled:hover:bg-secondary disabled:hover:text-white dark:focus:ring-white/80">
                                    ✍️ Skriv en text
                                </Link>
                            </div>

                    </div>
                </div>
            </div>
        </section>
    );
}
