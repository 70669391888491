import React, {useEffect, useState} from "react";
import {WEB_URL} from "../../vars";
import WidgetWallPage from "../frame_widget_wall";
import WidgetAvatarPage from "../frame_widget_avatar";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Mixpanel} from "../../Mixpanel";
import {useLocation} from "react-router-dom";
import useScript from "../../useScript";


export const UserProofBlock = (props) => {
    const {id} = props;
    const [text1, setText1] = useState("Fler än {number} nöjda kunder denna vecka");
    const [initialNum, setInitialNum] = useState(7);

    const codeString = "<div class=\"testimonial-frame-avatar\" data-id=\""+id+"\" data-text=\""+text1+"\" data-initial-number=\""+initialNum+"\" ></div>\n" +
        "<script defer type=\"text/javascript\" src=\""+WEB_URL+"/embedProfiles.min.js\"></script>"

    const codeString2 = "<div class=\"testimonial-frame-avatar\" data-id=\""+id+"\" data-text=\""+text1+"\" data-initial-number=\""+initialNum+"\" data-show-quote=\"true\" ></div>\n" +
        "<script defer type=\"text/javascript\" src=\""+WEB_URL+"/embedProfiles.min.js\"></script>"


    const initalText = text1.replace("{number}", initialNum.toString())
    const location = useLocation();

    const scriptLoaded = useScript('https://app.kundreferens.se/embedProfiles.min.js');

    useEffect(() => {
        console.log(location.pathname);
        Mixpanel.track('Viewed User Proof');
    }, [location]);




    return (
        <main className="mx-auto max-w-content-xl">
            <header className="mt-6 flex flex-col space-y-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
                <h1 className="text-3xl font-semibold text-heading">
                    Lägg enkelt in detta på din hemsida.
                </h1>
            </header>

            <div className="mt-4 flex flex-col space-y-6 md:mt-5 xl:grid xl:grid-cols-2 xl:gap-4 xl:space-y-0">

                <div className="col-span-2 rounded-xl bg-layer-2 px-8 py-6">
                    <div className="flex items-center justify-between">
                        <h2 className="text-lg font-semibold text-heading ">
                            Används väldigt ofta högt upp på din hemsida för att skapa en trygghet över att din tjänst / produkt är omtyckt av andra. Du kan se hur det ser ut på vår hemsida.
                        </h2>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="font-semibold mt-2">
                            Endast testimonials som är aktiva / publicerade kommer synas på din hemsida, du kan enkelt publicera dem från listan av rekommendationer.
                        </p>
                    </div>

                    <div className="mt-6 overflow-x-auto scrollbar">
                        <WidgetAvatarPage id={id} initialText={initalText} initialNum={initialNum} fetchAll={true}/>
                    </div>
                    <div className="mt-16 overflow-x-auto scrollbar">
                        <p>Klistra in denna kodsnutt på din hemsida där du vill att widgeten ska synas.</p>
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {codeString}
                        </SyntaxHighlighter>
                        <p className="mt-16">⭐️ TIPS. Du kan enkelt anpassa texten genom att ändra värdena på text och initial-number. Tex så här. "number" kommer automatiskt göras om till att visa siffran över antalet referenser du har.</p>

                    </div>

                    <div className="mt-16 overflow-x-auto scrollbar">
                        {!scriptLoaded && (
                            <p>Loading...</p>
                        )}
                        <div className="testimonial-frame-avatar-2" data-id={id}
                             data-text="Fler än {number} nöjda kunder denna vecka" data-initial-number="7"
                             data-show-quote="true"></div>
                        <p className="font-semibold mt-2 mb-2">Du kan även visa ett citat genom att lägga till data-show-quote</p>
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {codeString2}
                        </SyntaxHighlighter>

                    </div>
                </div>
            </div>
        </main>
    )

}
