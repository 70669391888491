import React, {useEffect, useState} from 'react';
import "../../index.css";
import {API_URL} from "../../vars";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase";
import {useNavigate, useLocation} from "react-router-dom";
import {Mixpanel} from "../../Mixpanel";



export const OnboardingBlock = (props) => {
    const {setProfile} = props;
    const [success, setSuccess] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [user, loading, error] = useAuthState(auth);

    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        // Send request to your server to increment page view count
        Mixpanel.track('Start Onboarding');
    }, [location]);

    const submit = (e) => {
        e.preventDefault()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user?.accessToken}` },
            body: JSON.stringify({
                name: name,
                message: message,
                email: user?.email
            })
        };
        fetch(`${API_URL}/profile`, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                // do whatever you want with the JSON response
                setProfile(jsonResponse?.data);
                if(!apiError) {
                    Mixpanel.track('Completed Onboarding');
                    setSuccess(true);
                    setApiError(false);
                }
            }).catch((error) => {
            // Handle the error
            console.log(error);
        }).finally(() => {
            console.log("Done with post...")
        });
    }

    const handleClose = () => {
        setApiError(!apiError);
    };

    return (
        <>
            <section className="bg-layer-1 p-6 ">
                <div className="mx-auto w-full max-w-xl ">
                    <div className="grid grid-cols-1 gap-6 ">
                        <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <h2 className="mt-8 text-3xl font-semibold text-heading md:text-3xl">
                                ⭐️ Välkommen ⭐️
                            </h2>
                            <p className={"mt-2"}>Vi behöver veta lite om dig för att slutföra registreringen. Du får mer än gärna lämna ett meddelande till oss och berätta om vilka förväntningar du har på oss.</p>

                            <form className="my-10" onSubmit={submit}>


                                <div className="mb-6 mt-5">
                                    <label htmlFor="name" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                        Ditt företagsnamn
                                    </label>
                                    <input type="text" id="name"
                                           className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                           placeholder="Skriv namnet på ditt företag"
                                           required={true}
                                           onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <textarea id="message" rows="5"
                                          required={false}
                                          className="mt-2 mb-4 block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                          placeholder="Har du testimonials / kundreferenser idag? Är det något du tycker att vi borde känna till om dig? ✨"
                                          onChange={(e) => setMessage(e.target.value)}
                                />


                                {apiError ? (
                                    <div
                                        className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
                                        role="alert">
                                        <strong className="font-bold">Ooops! </strong>
                                        <span className="block sm:inline">Testa igen om en liten stund.<br/>
                                    <button type="button" onClick={handleClose} className={"text-white font-regular px-5 py-2.5 text-center bg-yellow-600 mt-2"}>Försök igen</button> </span>
                                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}
                                             viewBox="0 0 20 20"><title>Close</title><path
                                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                    </span>
                                    </div>
                                ) : (
                                    <>
                                        <button type="submit"
                                                className={`w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2  focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}>
                                            Spara
                                        </button>

                                        {success && (
                                            <div
                                                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mt-5 rounded relative"
                                                role="alert">
                                                <strong className="font-bold">Sparad!</strong>
                                            </div>
                                        )}
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
