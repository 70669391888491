
export default function TestimonialTextPreview(props) {
    const {name, text, quote, avatarFile, tagline} = props;
    return (
        <section className="p-1 text-center rounded-xl border-2 border-muted-1">
            <div className="mx-auto w-full max-w-6xl">
                <div className="flex w-full flex-col md:flex-row">
                    <div className="w-full p-5 text-center md:p-5">
                        <h3 className="text-lg font-semibold text-heading">
                            {quote}
                        </h3>
                        <p className="mt-2 pl-0 pr-0 pt-0 text-center text-base text-heading">
                            {text}
                        </p>
                        <img
                            src={avatarFile}
                            alt="Din profil"
                            className="mt-3 inline-block h-12 w-12 rounded-full"
                        />
                        <p className="mt-3 text-xs text-text">{name}, {tagline}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
