import {
    LightBulbIcon,
    ArrowSmRightIcon,
    SunIcon,
    ShieldCheckIcon,
    BellIcon,
} from "@heroicons/react/outline";
import {URL_SIGNUP} from "../constants";
import {Link} from "react-router-dom";

export default function AdditionalLandingPageText() {
    return (
        <section className="w-full bg-layer-1 p-6 text-center md:p-20">
            <div className="mx-auto w-full max-w-6xl">
        <span className="leading-sm inline-flex items-center rounded-full border-2 border-orange-200 bg-orange-200 px-2 py-1 text-xs font-bold font-semibold uppercase text-orange-600 shadow-sm">
          <LightBulbIcon className="mr-1 h-5 w-5" />
          Features
        </span>
                <h2 className="mx-auto mt-4 text-center text-3xl font-semibold tracking-tight text-heading md:max-w-2xl md:text-6xl">
                    Är du inte övertygad?
                </h2>
                <p className="mt-6 text-lg text-text">
                    Du kan läsa mer om våra funktioner nedan
                </p>

                <div className="mt-12 grid grid-cols-1 gap-6 text-left md:grid-cols-2">
                    <div className="relative space-y-4 rounded-3xl py-6 px-8 hover:bg-layer-2">
                        <Link
                            to={URL_SIGNUP}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="absolute inset-0"
                        >
                            <span className="sr-only">Skapa konto</span>
                        </Link>
                        <div className="grid h-14 w-14 place-items-center rounded-2xl bg-muted-1 p-3">
                            <SunIcon className="h-8 w-8  gradient-fresh-mint" />
                        </div>
                        <h3 className="text-xl font-semibold tracking-tight text-heading">
                            📈Få insikt i hur dina video testimonials presterar
                        </h3>
                        <p className="font-medium text-text">
                            Ditt marknadsföringsteam får en snabb insikt i vilka video testimonials som presterar bäst, varför inte välja ut dina bästa testimonials och visa dem i sociala medier kanaler eller i ditt nyhetsbrev?
                        </p>
                        <span className="flex space-x-2 font-medium bg-gradient-to-r text-gradient from-fuchsia-600 to-indigo-600">
              Kom igång nu <ArrowSmRightIcon className="h-6 w-6 text-[#4f46e5] dark:text-[#fda4af]" />
            </span>
                    </div>
                    <div className="relative space-y-4 rounded-3xl py-6 px-8 hover:bg-layer-2">
                        <Link to={URL_SIGNUP}
                            rel="noreferrer noopener"
                            className="absolute inset-0"
                        >
                            <span className="sr-only">Skapa konto</span>
                        </Link>
                        <div className="grid h-14 w-14 place-items-center rounded-2xl bg-muted-1 p-3">
                            <ShieldCheckIcon className="h-8 w-8 gradient-peach" />
                        </div>
                        <h3 className="text-xl font-semibold tracking-tight text-heading">
                            📝 En dashboard för alla dina testimonials
                        </h3>
                        <p className="font-medium text-text">
                            Du kan hantera alla dina testimonials, både text och video i din dashboard, du kan en enkelt välja vilka videos som du vill framhäva på din hemsida - helt utan kodkunskaper!
                        </p>
                        <span className="flex space-x-2 font-medium bg-gradient-to-r text-gradient from-fuchsia-600 to-indigo-600">
              Kom igång nu <ArrowSmRightIcon className="h-6 w-6 text-[#4f46e5] dark:text-[#fda4af]" />
            </span>
                    </div>
                    <div className="relative space-y-4 rounded-3xl py-6 px-8 hover:bg-layer-2">
                        <Link to={URL_SIGNUP}
                            rel="noreferrer noopener"
                            className="absolute inset-0"
                        >
                            <span className="sr-only">Skapa konto</span>
                        </Link>
                        <div className="grid h-14 w-14 place-items-center rounded-2xl bg-muted-1 p-3">
                            <BellIcon className="h-8 w-8  gradient-sky" />
                        </div>
                        <h3 className="text-xl font-semibold tracking-tight text-heading">
                            🤝 Hantera samtycken på ett smidigt sätt
                        </h3>
                        <p className="font-medium text-text">
                            Vi ser till att du alltid har ett GDPR säkrat samtycke så att du kan använda filmer och recensioner som dina kunder lämnar i din marknadsföring.
                        </p>
                        <span className="flex space-x-2 font-medium bg-gradient-to-r text-gradient from-fuchsia-600 to-indigo-600">
              Kom igång nu <ArrowSmRightIcon className="h-6 w-6 text-[#4f46e5] dark:text-[#fda4af]" />
            </span>
                    </div>
                    <div className="relative space-y-4 rounded-3xl py-6 px-8 hover:bg-layer-2">
                        <Link to={URL_SIGNUP}
                            rel="noreferrer noopener"
                            className="absolute inset-0"
                        >
                            <span className="sr-only">Skapa konto</span>
                        </Link>
                        <div className="grid h-14 w-14 place-items-center rounded-2xl bg-muted-1 p-3">
                            <SunIcon className="h-8 w-8  gradient-dusk" />
                        </div>
                        <h3 className="text-xl font-semibold tracking-tight text-heading">
                            Obegränsad, reklamfri hosting av dina videotestimonials
                        </h3>
                        <p className="font-medium text-text">
                           Vi visar aldrig reklam i dina video eller text testimonials!
                        </p>
                        <span className="flex space-x-2 font-medium bg-gradient-to-r text-gradient from-fuchsia-600 to-indigo-600">
              Kom igång nu <ArrowSmRightIcon className="h-6 w-6 text-[#4f46e5] dark:text-[#fda4af]" />
            </span>
                    </div>
                </div>
            </div>
        </section>
    );
}
