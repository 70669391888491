export const suggestions = [
    "Kvalitet",
    "Service",
    "Tillförlitlighet",
    "Professionalism",
    "Förtroende",
    "Hjälpsamhet",
    "Effektivitet",
    "Prisvärdhet",
    "Leveranstid",
    "Kundsupport",
    "Produktfunktioner",
    "Erfarenhet",
    "Pålitlighet",
]
