import './App.css';
import HomePage from './pages/home'
import ScrollToTop from "./scrollToTop";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import {TestimonialStart} from "./pages/testimonialStart";
import {TestimonialCapture} from "./pages/testimonialCapture";
import {TestimonialCaptureText} from "./pages/testimonialCaptureText";
import WidgetAvatarPage from "./pages/frame_widget_avatar";
import WidgetWallPage from "./pages/frame_widget_wall";
import DashboardPage from "./pages/dashboard/dashboard";
import Signup from "./pages/auth/signup";
import {URL_DASHBOARD} from "./constants";
import Login from "./pages/auth/login";
import ResetPassword from "./pages/auth/reset";
import TestimonialCaptureAI from "./pages/testimonialCaptureAI";

function VideoPreviewPage() {
    return null;
}

function App() {

  return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/about" element={<HomePage />} />
          <Route path={URL_DASHBOARD} element={<DashboardPage />} />
          <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/reset" element={<ResetPassword />} />
          <Route path="/:board" element={<TestimonialStart />} />
          <Route path="/record/:board" element={<TestimonialCapture />} />

          <Route path="/write-basic/:board" element={<TestimonialCaptureText />} />
            <Route path="/write-ai/:board" element={<TestimonialCaptureAI />} />
          <Route path="/embed/avatar/:id" element={<WidgetAvatarPage />} />
          <Route path="/embed/wall/:id" element={<WidgetWallPage />} />
          <Route
              path='/videoPreview'
              render={(props) => (
                  <VideoPreviewPage {...props}  />
              )}
          />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
