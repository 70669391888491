import React, {useEffect, useState} from 'react';
import {API_URL_PUBLIC, avatarOptions} from "../vars";
import {useParams, useSearchParams} from "react-router-dom";
import FileBase64 from 'react-file-base64';
import ContentLoader from "react-content-loader";
import TestimonialTextPreview from "../components/testimonialText";
import {ExclamationIcon, XIcon} from "@heroicons/react/outline";
import {getDownloadURL, ref, updateMetadata, uploadBytesResumable} from "firebase/storage";
import {storage} from "../firebase";
import {trackTestimonialCreateText} from "../tracking";
import {Mixpanel} from "../Mixpanel";
import getRandomAvatar, {generateUUID, makeid} from "../utils";
import {suggestions} from "../suggestions";
import ToggleCheckbox from "../components/ToggleCheckbox";

function TestimonialCaptureAI() {
    const [name, setName] = useState('');
    const [fetchingSuggestion, setFetchingSuggestion] = useState(false);
    const [email, setEmail] = useState('');
    const [tagline, setTagline] = useState('');
    const [quote, setQuote] = useState('');
    const [words, setWords] = useState('');
    const [response, setResponse] = useState('');
    const [isContinue, setShowContinue] = useState(false);
    const [editableTestimonial, setEditableTestimonial] = useState('');
    const [defaultAvatar, setDefaultAvatar] = useState("");
    const [file, setFile] = useState(undefined)
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [uploadFile, setUploadFile] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [percent, setPercent] = useState(0);
    const [success, setSuccess] = useState(false);
    const [showMissingWordsMsg, setShowMissingWordsMsg] = useState(false);
    const [showMissingNameMsg, setShowMissingNameMsg] = useState(false);
    const [showEditText, setShowEditText] = useState(false)
    const [selectedSuggestedWords, setSelectedSuggestedWords] = useState([])
    const [consentGiven, setConsentGiven] = useState(false);

    const { board } = useParams();
    const [searchParams] = useSearchParams();
    const profileName = searchParams.get("name") || ""
    const lang = searchParams.get("lang") || "se"

    const mixpanelProps = {
        'profile': board,
        'pageType': "ai_assisted_write_text_testimonial"
    }

    useEffect(() => {
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
        if(defaultAvatar === "") generateRandomAvatar();
        Mixpanel.track('Testimonial Step 1', mixpanelProps);
    })

    useEffect(() => {
        if(fetchingSuggestion) {
            setTimeout(() => {
                window.scrollBy({ top: 250, behavior: 'smooth' });
            }, 50)
        }
    }, [fetchingSuggestion])

    const generateRandomAvatarBtn = () => {
        Mixpanel.track('Click Generate Avatar', mixpanelProps);
        generateRandomAvatar()
    }
    const openPhotoPickerOptionButton = () => {
        Mixpanel.track('Click Show Profile Show Upload / Take Photo Button', mixpanelProps);
        setUploadFile(true)
    }

    function generateRandomAvatar() {
        setDefaultAvatar(getRandomAvatar);
    }

    function getFile(file){
        Mixpanel.track('Selected Or Took Profile Photo', mixpanelProps);
        setFile(file)
    }

    const handleGetSuggestion = async (e) => {
        if(!name) {
            setShowMissingNameMsg(true);
            return
        }
        setShowMissingNameMsg(false);
        if(!words) {
            setShowMissingWordsMsg(true);
            return
        }

        Mixpanel.track('Click Get Suggestion Text ', mixpanelProps);
        setShowEditText(false);
        setFetchingSuggestion(true);
        setApiError(false);
        setApiErrorMsg('');
        setShowMissingWordsMsg(false);
        e.preventDefault();
        let queryParams = `?profile=${board}&words=${words}&companyname=${profileName}&lang=${lang}`
        fetch(`${API_URL_PUBLIC}/review-suggestion/${board}/${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                })));
        }).then(({ status, json, ok }) => {
            switch (status) {
                case 400:
                    setApiError(true);
                    console.log(json)
                    setApiErrorMsg(json.error);
                    break;
                case 200:
                    setResponse(json.data?.text);
                    setQuote(json.data?.quote);
                    setEditableTestimonial(json.data?.text);
                    break;
                case 500:
                default:
                    setApiError(true);
                    setApiErrorMsg("please wait a while and then try again");
            }
        }).catch(error => {
            setApiError(true);
            setApiErrorMsg("Server error");
        }).finally(() => {
            setFetchingSuggestion(false);
        })

    };

    const handleFeedback = (isPositive) => {
        setShowContinue(isPositive);
    };

    const toggleShowEditText = () => {
        Mixpanel.track('Click Button Show Edit Text', mixpanelProps);
        showEditText ? setShowEditText(false) : setShowEditText(true)
    }


    let downloadUrl
    let fileName = generateUUID();
    const handleSubmitReview = (e) => {
        Mixpanel.track('Click Form Submit', mixpanelProps);
        e.preventDefault()
        if(!file) {
            console.error(`not an image, the image file is a ${typeof(file)}`)
            submitReviewToBackend(e)
        } else {
            let fileType = file.name.match(/\.([^.]+)$/)[1];
            fileName += "." + fileType;
            const storageRef = ref(storage, `/files/${fileName}`)
            // Create file metadata to update
            const newMetadata = {
                customMetadata: {
                    'board': board,
                }
            };
            const uploadTask = uploadBytesResumable(storageRef, file.file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.log("SOME ERROR", err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        downloadUrl = url;
                    });

                    updateMetadata(storageRef, newMetadata)
                        .then((metadata) => {
                            // Updated metadata for file is returned in the Promise
                            submitReviewToBackend(e)
                        }).catch((error) => {
                        // Uh-oh, an error occurred!
                    });
                }
            );
        }
    }

    const submitReviewToBackend = (e) => {
        e.preventDefault()
        let fileToSend = {};
        if(!file) {
            fileToSend.base64 = defaultAvatar
            fileToSend.name = makeid()
            fileToSend.type = "image/svg+xml"
        } else {
            fileToSend.base64 = downloadUrl
            fileToSend.name = fileName
            fileToSend.type = file.type
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                profile_id: board,
                author: name,
                tagline: tagline,
                email: email,
                rating: rating,
                text: editableTestimonial,
                quote: quote,
                base64: fileToSend.base64,
                name: fileToSend.name,
                type: fileToSend.type,
            })
        };
        fetch(`${API_URL_PUBLIC}/review`, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                // do whatever you want with the JSON response
                if(!apiError) {
                    trackTestimonialCreateText();
                    Mixpanel.track('Show Thank You Page', mixpanelProps);
                    setSuccess(true)
                    setApiError(false)
                }
            }).catch((error) => {
            // Handle the error
            console.log(error);
        });
    }


    const handleEditTestimonial = (e) => {
        setEditableTestimonial(e.target.value);
    };

    const handleEditTestimonialQuote = (e) => {
        setQuote(e.target.value)
    };

    const handleContinueButton = (e) => {
        handleFeedback(true);
        Mixpanel.track('Click Continue To Step 2', mixpanelProps);
        setTimeout(() => {
            window.scrollBy({ top: 450, behavior: 'smooth' });
        }, 50)
    }

    const setTestimonialRating = (rating) => {
        setRating(rating)
        const ratingProps = {
            'rating': rating,
            ...mixpanelProps
        }
        Mixpanel.track('User Left Rating', ratingProps)
    }

    const toggleWord = (word) => {
        let addedSuggestions = selectedSuggestedWords;
        const wordIndex = addedSuggestions.indexOf(word);

        // If the word is already selected, unselect it
        if (wordIndex !== -1) {
            addedSuggestions.splice(wordIndex, 1);
            setSelectedSuggestedWords(addedSuggestions);

            // Update words string
            const wordsArray = words.split(', ');
            wordsArray.splice(wordIndex, 1);
            setWords(wordsArray.join(', '));
        }
        // If the word is not selected, select it
        else {
            let wordsToAppend = words;
            if(words.length)  {
                wordsToAppend += ', ';
            }
            wordsToAppend += word;
            setWords(wordsToAppend);
            addedSuggestions.push(word);
            setSelectedSuggestedWords(addedSuggestions);
        }
    }



    const isSelectedSuggestion = (word) => {
        return selectedSuggestedWords.includes(word);
    }

    const handleToggleChange = (isChecked) => {
        setConsentGiven(isChecked);
    };

    const consentText = `Genom att klicka på knappen nedan så godkänner jag att text, namn och bild kan komma att användas i marknadsföring. Du kan ta tillbaka ditt samtycke genom att kontakta ${profileName}.`

    return (
        <>
        {success ? (
            <section className="px-6 mt-20 ">
                <div className="mt-80 text-center max-w-4xl m-auto">
                    <h2 className="text-4xl font-semibold text-heading md:text-5xl">
                        🙏 Tack!
                    </h2>
                    <p className="mt-10 mx-auto mt-4 max-w-3xl text-base text-text sm:mt-6 sm:text-lg">Vi uppskattar verkligen att du tog dig tid att skriva en recension!</p>
                </div>
            </section>

        ) : (
        <section className="bg-layer-1 p-6 md:p-20 align-middle">
            <div className="mx-auto w-full max-w-xl ">
                <div className="grid grid-cols-1 gap-6 ">
                    <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <h2 className="mt-8 text-xl font-semibold text-heading md:text-xl">
                                Lämna en referens åt {profileName}
                            </h2>
                        <p className={"text-text mt-2 mb-4"}>Vår AI hjälper dig genom att generera ett utkast som du enkelt kan anpassa tills du blir helt nöjd.</p>
                            <div className="mb-6">
                                <label htmlFor="name" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                    Ditt namn
                                </label>
                                <input type="text" id="name"
                                       className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                       placeholder="Ditt namn"
                                       required={true}
                                       onChange={(e) => setName(e.target.value)}
                                />
                                {showMissingNameMsg && (
                                    <p className={"text-text  mt-2 text-red-500 font-medium"}>Du behöver fylla ditt namn</p>
                                )}
                            </div>
                            <div className="mb-6">
                                <label htmlFor="suggestions" className="block text-left mb-1 text-xs font-bold text-gray-900 dark:text-gray-300">
                                   Välj en eller flera påstående som passar in, eller skriv några ord / korta meningar som du vill lyfta fram hos {profileName}
                                </label>

                                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap',  margin: 'auto', justifyContent: 'space-around', alignItems: 'center'}}>
                                    {suggestions.map((suggestion, idx) => {
                                        return (
                                            <span
                                                key={idx}
                                                onClick={() => toggleWord(suggestion, idx)}
                                                className={'inline-flex mt-2 items-center rounded-full border-2 border-muted-1 bg-muted-1 px-2 py-1 text-sm font-semibold text-heading shadow-sm cursor-pointer ' + (isSelectedSuggestion(suggestion) ? 'bg-muted-3 border-muted-3' : '')}>
                                            {suggestion}
                                            </span>
                                        )

                                    })
                                    }
                                </div>
                                <input type="text" id="words"
                                       className="mt-4  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                       placeholder="Skriv några saker som du är nöjd med"
                                       required={true}
                                       value={words}
                                       onChange={(e) => setWords(e.target.value)}
                                /><p className="block text-left mb-2 mt-2 text-text font-small text-gray-900 dark:text-gray-300">
                                    Du kan självklart skriva något samtidigt som du använder snabbvalen.
                                </p>
                                {showMissingWordsMsg && (
                                    <p className={"text-text  mt-2 text-red-500 font-medium"}>Du behöver fylla något som du tycker är bra</p>
                                )}
                                {response ? (
                                    <button
                                        type="button"
                                        onClick={handleGetSuggestion}
                                        disabled={fetchingSuggestion}
                                        className={`mt-6 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-secondary bg-secondary px-4 py-2.5 text-base font-semibold text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent focus:outline-none focus:ring-2 focus:ring-primary/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-secondary disabled:hover:bg-secondary disabled:hover:text-white dark:focus:ring-white/80`}>
                                        {fetchingSuggestion ? "Vi skapar din text, kan ta upp till 30 sekunder..." : "✏️️ Generera en ny text"}
                                    </button>

                                ) : (
                                    <button
                                        type="button"
                                        disabled={fetchingSuggestion}
                                        onClick={handleGetSuggestion}
                                        className={`mt-6 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}>
                                        {fetchingSuggestion ? "Vi skapar din text, kan ta upp till 30 sekunder..." : "📝 Föreslå en text åt mig"}
                                    </button>

                                ) }

                                {apiError && (
                                    <div className="relative rounded-xl bg-layer-1 p-4 pr-16 mt-4">
                                        <div className="flex items-start space-x-2.5 text-red-400">
                                            <ExclamationIcon className="h-6 w-6 flex-shrink-0" />
                                            <div>
                                                <div className="mt-0.5 text-sm font-semibold">
                                                    Failed to generate suggestion
                                                </div>
                                                <div className="mt-1 text-xs font-medium text-text">
                                                    {apiErrorMsg}<br /><br />
                                                    Please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {fetchingSuggestion && (
                                    <>
                                        <p className={"text-text"}> </p>
                                        <ContentLoader  viewBox="0 0 430 230" >
                                            <rect x="0" y="15" rx="2" ry="2" width="100%" height="80" />
                                        </ContentLoader>
                                    </>
                                )}
                            </div>
                            {(response && !fetchingSuggestion) && (
                                <>
                                    <button
                                        type="button"
                                        onClick={toggleShowEditText}
                                        className={'mt-1 mb-4 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-transparent bg-transparent px-4 py-2.5 text-lg font-semibold text-primary hover:bg-heading/5 focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text'} >
                                        Redigera texten
                                    </button>

                                    {showEditText && (
                                        <>
                                            <input type="text" id="quote"
                                                   className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                                   placeholder="Ditt citat" required={true}
                                                   value={quote}
                                                   onChange={handleEditTestimonialQuote}
                                                   onBlur={() => Mixpanel.track('User Edited Suggested Quote', mixpanelProps)}
                                            />
                                            <textarea id="message" rows="6"
                                            required={true}
                                            value={editableTestimonial}
                                            className="mt-2 mb-4 block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                            placeholder="Skriv något om oss ✨"
                                            onChange={handleEditTestimonial}
                                            onBlur={() => Mixpanel.track('User Edited Suggested Text', mixpanelProps)}
                                            />
                                        </>
                                    )}
                                <TestimonialTextPreview name={name} text={editableTestimonial} quote={quote} avatarFile={file?.base64 || defaultAvatar} tagline={tagline}/>

                                <div className="mb-2 mt-4">
                                    <label htmlFor="email" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                        Ändra din profilbild
                                    </label>
                                        <div>

                                            {/**
                                             * all buttons use regular Button classes except for border radius (remove "rounded-xl")
                                             * extra classes for all buttons: "relative focus:z-10"
                                             * extra classes for all buttons EXCEPT for the first one: "-ml-0.5"
                                             */}

                                            {/* leading classes: "rounded-l-xl" */}
                                            <button
                                                type="button"
                                                className="relative inline-flex cursor-pointer items-center justify-center rounded-l-xl border-2 border-muted-3 bg-transparent px-1 py-2.5 text-sm font-semibold text-text  shadow-sm hover:text-heading focus:z-10 focus:text-heading focus:outline-none focus:ring-2 focus:ring-muted-3 focus:ring-offset-0 disabled:opacity-30 disabled:hover:text-text dark:focus:ring-white/80"
                                                onClick={generateRandomAvatarBtn}
                                            >
                                                ⚙ Generera
                                            </button>

                                            <button
                                                type="button"
                                                onClick={openPhotoPickerOptionButton}
                                                className="relative -ml-0.5 inline-flex cursor-pointer items-center justify-center border-2 border-muted-3 bg-transparent px-1 py-2.5 text-sm font-semibold text-text  shadow-sm hover:text-heading focus:z-10 focus:text-heading focus:outline-none focus:ring-2 focus:ring-muted-3 focus:ring-offset-0 disabled:opacity-30 disabled:hover:text-text dark:focus:ring-white/80"
                                            >
                                                📷 Kamera
                                            </button>

                                            {/* trailing classes: "rounded-r-xl" */}
                                            <button
                                                type="button"
                                                onClick={openPhotoPickerOptionButton}
                                                className="relative -ml-0.5 inline-flex cursor-pointer items-center justify-center rounded-r-xl border-2 border-muted-3 bg-transparent px-1 py-2.5 text-sm font-semibold text-text  shadow-sm hover:text-heading focus:z-10 focus:text-heading focus:outline-none focus:ring-2 focus:ring-muted-3 focus:ring-offset-0 disabled:opacity-30 disabled:hover:text-text dark:focus:ring-white/80"
                                            >
                                                📂 Ladda upp
                                            </button>
                                     </div>
                                    {uploadFile && (
                                        <>
                                            <p className={"text-text mt-2  mb-2"}>Ta en bild med din mobilkamera eller ladda upp en bild på dig själv - klicka på knappen nedan</p>
                                            <div className="input-file ">
                                                <FileBase64
                                                    id="avatar"
                                                    multiple={ false }
                                                    onDone={ getFile } />
                                            </div>
                                            <p className={"text-muted1 text-sm mt-2"}>Ladda t.ex upp din profilbild från LinkedIn, Twitter Facebook...</p>


                                        </>
                                    )}




                                </div>

                                {!isContinue && (
                                <div className="mb-6">

                                    <button
                                        type="button"
                                        onClick={handleContinueButton}
                                        className={`mt-6 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}>
                                        Fortsätt
                                    </button>
                                </div>
                                )}
                            </>
                            )}
                    </div>
                            {isContinue && (
                                <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6 mb-12">
                                    <h5 className="mt-8 text-xl font-semibold text-heading md:text-l">
                                        Toppen! Vi behöver några detaljer till om dig
                                    </h5>


                                    <div className="mb-2 mt-6">
                                        <label htmlFor="email" className="block text-left text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Hur många stjärnor ger du {profileName}?
                                        </label>
                                        <div className="star-rating mt-1">
                                            {[...Array(5)].map((star, index) => {
                                                index += 1;
                                                return (
                                                    <button
                                                        type="button"
                                                        key={index}
                                                        className={index <= (hover || rating) ? "on " : "off"}
                                                        onClick={() => setTestimonialRating(index)}
                                                        onMouseEnter={() => setHover(index)}
                                                        onMouseLeave={() => setHover(rating)}
                                                    >
                                                        <span className="star text-2xl">&#9733;</span>
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-4">
                                        <label htmlFor="email" className="block text-left mb-1 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Din mejl
                                        </label>
                                        <p className="block text-left mb-2 text-xs font-small text-gray-900 dark:text-gray-300">
                                            Så att vi vet vem referensen kommer ifrån.
                                        </p>
                                        <input type="email" id="email"
                                               className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                               placeholder="din@mejl.com" required={true}
                                               onChange={(e) => setEmail(e.target.value)}
                                        />

                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="name" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Din roll och företag
                                        </label>
                                        <input type="text" id="name"
                                               className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                               placeholder="T.ex. Grundare, Kundreferens"
                                               required={true}
                                               onChange={(e) => setTagline(e.target.value)}
                                        />
                                    </div>


                                    <div className={"mt-4"}>
                                        <ToggleCheckbox  text={consentText} onChange={handleToggleChange}></ToggleCheckbox>
                                    </div>

                                    <button
                                        onClick={handleSubmitReview}
                                        type="button"
                                        disabled={!consentGiven || !tagline || !email || !rating}
                                        className={`mt-6 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}>
                                        Skicka in
                                        {percent > 0 && (
                                            <svg aria-hidden="true"
                                                 className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                 viewBox="0 0 100 101" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"/>
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"/>
                                            </svg>
                                        )}
                                    </button>
                                    {(!consentGiven || !tagline || !email || !rating) && (
                                        <p className="mt-2 block text-sm text-red-500 text-text">
                                            Du behöver fylla i samtliga fält
                                        </p>
                                    )}
                                    {apiError && (
                                        <div className="relative rounded-xl bg-layer-1 p-4 pr-16 mt-4">
                                            <div className="flex items-start space-x-2.5 text-red-400">
                                                <ExclamationIcon className="h-6 w-6 flex-shrink-0" />
                                                <div>
                                                    <div className="mt-0.5 text-sm font-semibold">
                                                        Failed to save your testimonial
                                                    </div>
                                                    <div className="mt-1 text-xs font-medium text-text">
                                                        Please try again.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                </div>
            </div>
        </section>
        )}
    </>
    );
}

export default TestimonialCaptureAI;
