export const API_URL_PUBLIC="https://testimonial.fly.dev/v1"
export const API_URL="https://testimonial.fly.dev/v1s"
export const WEB_URL="https://app.kundreferens.se"
export const EMBED_URL="https://embed.testimonial.se"
/*export const API_URL_PUBLIC="http://localhost:3002/v1"
export const API_URL="http://localhost:3002/v1s"
export const WEB_URL="http://localhost:3000"
export const EMBED_URL="http://127.0.0.1:5173"

 */



