import mixpanel from 'mixpanel-browser';
// or with require() syntax:
//const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

let debugMode = true

// eslint-disable-next-line no-restricted-globals
if (location.hostname !== 'localhost') {
    debugMode = false;
}

mixpanel.init('b0e15a2fe8fa649457c352b4f041ddd7', {debug: debugMode});

let actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
};

export let Mixpanel = actions;
