import { logEvent } from "firebase/analytics";
import {analytics} from './firebase'

const isLocalHost = () => {
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname === "localhost") return true
}

export function trackSignup() {
    if (isLocalHost()) return;
    logEvent(analytics, 'sign_up');
    console.log("SIGNED UP TRACKING")
}

export function trackLogin() {
    if (isLocalHost()) return;
    logEvent(analytics, 'login');
    console.log("LOGGED IN UP TRACKING")
}

export function trackTestimonialPublish() {
    if (isLocalHost()) return;
    logEvent(analytics, 'goal_completion', { name: 'testimonial_published'});
    console.log("PUBLISH Testimonial")
}

export function trackTestimonialImport() {
    if (isLocalHost()) return;
    logEvent(analytics, 'goal_completion', { name: 'testimonial_imported'});
    console.log("Testimonial imported")
}

export function trackTestimonialCreateText() {
    if (isLocalHost()) return;
    logEvent(analytics, 'goal_completion', { name: 'testimonial_created_text'});
    console.log("Testimonial created text")
}

export function trackTestimonialCreateVideo() {
    if (isLocalHost()) return;
    logEvent(analytics, 'goal_completion', { name: 'testimonial_created_video'});
    console.log("Testimonial created video")
}
