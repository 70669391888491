import React, {useEffect, useState} from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {docco} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {Mixpanel} from "../../Mixpanel";
import {useLocation} from "react-router-dom";
import {WEB_URL} from "../../vars";
import useScript from "../../useScript";


export const PanelBlock = (props) => {
    const {id} = props;
    const codeString = "<div class=\"testimonial-frame-panel\" data-id=\""+id+"\" ></div>\n" +
        "<script defer type=\"text/javascript\" src=\""+WEB_URL+"/embedPanel.min.js\"></script>"
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        Mixpanel.track('Viewed panel embed page');
    }, [location]);

    const scriptLoaded = useScript('https://app.kundreferens.se/embedPanel.min.js');

    return (
        <main className="mx-auto max-w-content-xl ">
            <header className="mt-6 flex flex-col space-y-4 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
                <h1 className="text-3xl font-semibold text-heading">
                    Sektion med referenser
                </h1>
            </header>

            <div className="mt-4 flex flex-col space-y-6 md:mt-5 xl:grid xl:grid-cols-2 xl:gap-4 xl:space-y-0 bg-white">

                <div className="col-span-2 rounded-xl border-muted-1 border-2 px-8 py-6">
                    <div className="flex ">
                        <p className="text-lg font-semibold text-heading ">
                           Denna panel / sektion är smidigast att lägga in som en del på en eller flera av dina sidor på din hemsida.
                        </p>
                    </div>
                    <div className="mt-6 overflow-x-auto scrollbar">
                        {!scriptLoaded && (
                            <p>Loading...</p>
                        )}
                        <div className="testimonial-frame-panel" data-id={id}>

                        </div>
                    </div>
                    <div className="mt-16 overflow-x-auto scrollbar">
                        <p>Kopiera och klistra in denna kodsnutt på din hemsida där du vill att widgeten ska synas.</p>
                        <SyntaxHighlighter language="javascript" style={docco}>
                            {codeString}
                        </SyntaxHighlighter>

                    </div>
                </div>

            </div>

        </main>
    )

}
