import {useEffect, useState} from "react";
import {API_URL_PUBLIC} from "../vars";
import {useLocation, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";
import ReactPlayer from "react-player";


export default function WidgetWallPage(props) {
    const { id } = useParams();
    const [apiError, setApiError] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [user, loading, error] = useAuthState(auth);
    const location = useLocation();
    const defaultAvatar = "https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShaggyMullet&accessoriesType=Sunglasses&hairColor=Blonde&facialHairType=Blank&clotheType=Hoodie&clotheColor=Gray02&eyeType=Default&eyebrowType=SadConcerned&mouthType=Smile&skinColor=Tanned"
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    let idParam = id;
    if(!id) {
        idParam = props.id
    }
    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
        fetch(`${API_URL_PUBLIC}/embed/avatar/${idParam}?limit=all`, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                console.log(jsonResponse.data)
                // do whatever you want with the JSON response
                setTestimonials(jsonResponse?.data)
                if(!apiError) {
                    setApiError(false)
                }
            }).catch((error) => {
            console.log("ERR")
            // Handle the error
            console.log(error);
        });
        // eslint-disable-next-line
    }, [location]);



    return (
        <section className={"mb-5"}>
            <div className="mx-auto w-full max-w-6xl w-full mx-auto px-4 sm:px-6">
                {/*<h2 className="flex flex-col text-center font-sans text-3xl font-semibold tracking-tight text-heading md:text-4xl">
                    What People Say
                </h2>
                */}
                <div className="mt-6 gap-6 sm:columns-2 md:columns-3">
                    {testimonials.map((user, index) => (
                    <div className="break-inside-avoid-column pt-6" key={index}>
                        <a
                            href="#"
                            rel="noreferrer noopener"
                            className={"block rounded-2xl bg-layer-0 pt-4 pb-5 border-2 border-solid border-layer-3 hover:bg-layer-3"}
                        >
                            <div className="flex justify-between px-4">
                                <div className="flex content-center items-center gap-2">
                                    <img
                                        src={user.img_base64 || defaultAvatar}
                                        alt={`${user.author}'s avatar`}
                                        className="inline-block h-12 w-12 rounded-full"
                                    />
                                    <div>
                                        <div className="font-semibold text-heading">
                                            {user.author}
                                        </div>
                                        {user.tagline && (

                                        <div className="text-text text-base text-sm">
                                            {user.tagline}
                                        </div>
                                        )}
                                    </div>
                                </div>
                                {/* <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 11C0 4.92487 4.92487 0 11 0V0C17.0751 0 22 4.92487 22 11V11C22 17.0751 17.0751 22 11 22V22C4.92487 22 0 17.0751 0 11V11Z"
                                        fill="#1877F2"
                                    />
                                    <path
                                        d="M15.2818 14.1797L15.7695 11H12.7188V8.9375C12.7188 8.06738 13.1441 7.21875 14.5105 7.21875H15.8984V4.51172C15.8984 4.51172 14.6395 4.29688 13.4363 4.29688C10.9227 4.29688 9.28125 5.82012 9.28125 8.57656V11H6.48828V14.1797H9.28125V21.8668C9.84199 21.9549 10.4156 22 11 22C11.5844 22 12.158 21.9549 12.7188 21.8668V14.1797H15.2818Z"
                                        fill="white"
                                    />
                                </svg>
                                    */}
                            </div>
                            {user.video_url ? (
                                <div className={"px-3 mt-2 rounded-lg"}>
                                    <ReactPlayer
                                        width="100%"
                                        height="100%"
                                        style={{borderRadius: 3, overflow: "hidden"}}
                                        url={user.video_url}
                                        controls
                                    />

                                </div>
                            ) : (
                                <p className="mt-2 px-4 text-base text-heading">
                                    “{user.text}”
                                </p>
                            )}
                        </a>
                    </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
