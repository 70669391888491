import React, {useEffect, useState} from 'react';
import VideoRecorder from "react-video-recorder";

import {useParams, useNavigate, Link, useSearchParams} from "react-router-dom";
import "../index.css";
import { ref, uploadBytesResumable, getDownloadURL, updateMetadata } from "firebase/storage";
import {storage} from '../firebase'
import {API_URL, API_URL_PUBLIC} from "../vars";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import FileBase64 from 'react-file-base64';
import {trackTestimonialCreateVideo} from "../tracking";
import {Mixpanel} from "../Mixpanel";
import {suggestions} from "../suggestions";
import ContentLoader from "react-content-loader";
import {ExclamationIcon} from "@heroicons/react/outline";
import ToggleCheckbox from "../components/ToggleCheckbox";
import getRandomAvatar, {generateUUID, makeid} from "../utils";

const DoVideoRecorder = ({ setVideoFile }) => {
    return (
        <VideoRecorder
            countdownTime={3000}
            constraints={{
                audio: true,
                video: {
                    width: { exact: 720, ideal: 720 },
                    height: { exact: 1280, ideal: 1280 },
                    aspectRatio: { exact: 0.8500000001, ideal: 0.8500000001 },
                    resizeMode: "crop-and-scale"
                }
            }}
            onRecordingComplete={(daVideoBlob) => {
                // Do something with the video...
                //push("/videoPreview", { daVideoBlob });


                let file = new File([daVideoBlob], `${generateUUID()}.mp4`, {
                    type: 'video/mp4'
                });
                setVideoFile(file)
            }}
            /* renderActions={({
              onStartRecording,
              onStopRecording,
              isCameraOn,
              streamIsReady,
              isVideoInputSupported,
              isInlineRecordingSupported,
              thereWasAnError,
              isConnecting,
              isRunningCountdown,
              isReplayingVideo
            }) => {
              console.log({ isReplayingVideo });
              if (
                (!isInlineRecordingSupported && !isVideoInputSupported) ||
                thereWasAnError ||
                isConnecting ||
                isRunningCountdown ||
                isReplayingVideo
              ) {
                return null;
              }

              return (
                <div style={{ position: "absolute", bottom: "10%" }}>
                  <button
                    onClick={() => {
                      if (isCameraOn && streamIsReady) {
                        onStartRecording();
                      }
                    }}
                  >
                    Record
                  </button>
                  <button onClick={onStopRecording}>Stop</button>
                </div>
              );
            }} */
        />
    );
};



export const TestimonialCapture = () => {
    const { board } = useParams();
    const navigate = useNavigate();

    const [success, setSuccess] = React.useState(false);
    const [apiError, setApiError] = useState(false);
    // State to store uploaded file
    const [videoFile, setVideoFile] = React.useState(undefined);
    const [step, setStep] = useState(1);
    const [defaultAvatar, setDefaultAvatar] = useState("");
    const [file, setFile] = useState(undefined)

    const [name, setName] = useState("");
    const [tagline, setTagline] = useState("");
    const [email, setEmail] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [message, setMessage] = useState("");

    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [showMissingNameMsg, setShowMissingNameMsg] = useState(false);
    const [words, setWords] = useState('');
    const [selectedSuggestedWords, setSelectedSuggestedWords] = useState([])
    const [showMissingWordsMsg, setShowMissingWordsMsg] = useState(false);
    const [fetchingSuggestion, setFetchingSuggestion] = useState(false);
    const [response, setResponse] = useState('');
    const [editableTestimonial, setEditableTestimonial] = useState('');
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [consentGiven, setConsentGiven] = useState(false);

    // progress
    const [percent, setPercent] = useState(0);

    const [searchParams] = useSearchParams();
    const profileName = searchParams.get("name") || ""
    const lang = searchParams.get("lang") || "se"

    const mixpanelProps = {
        'profile': board,
        'pageType': "ai_assisted_record_video"
    }


    let downloadVideoUrl
    let downloadImageUrl
    let fileImageName = generateUUID();

    const submitReviewToBackend = () => {
        let fileToSend = {};
        if(!file) {
            fileToSend.base64 = defaultAvatar
            fileToSend.name = makeid()
            fileToSend.type = "image/svg+xml"
        } else {
            fileToSend.base64 = downloadImageUrl
            fileToSend.name = fileImageName
            fileToSend.type = file.type
        }

        console.log({
            profile_id: board,
            author: name,
            tagline: tagline,
            rating: rating,
            email: email,
            text: message,
            video_url: downloadVideoUrl,
            base64: fileToSend.base64,
            name: fileToSend.name,
            type: fileToSend.type,
    })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                profile_id: board,
                author: name,
                tagline: tagline,
                rating: rating,
                email: email,
                text: message,
                video_url: downloadVideoUrl,
                base64: fileToSend.base64,
                name: fileToSend.name,
                type: fileToSend.type,
            })
        };
        fetch(`${API_URL_PUBLIC}/review`, requestOptions)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    setApiError(true)
                    throw Error(response.statusText);
                }
            })
            .then((jsonResponse) => {
                // do whatever you want with the JSON response
                if(!apiError) {
                    trackTestimonialCreateVideo()
                    Mixpanel.track('Successful Testimonial Video', {'profile': board});
                    setSuccess(true)
                    setApiError(false)
                }
            }).catch((error) => {
            // Handle the error
            console.log(error);
        });
    }

    const newMetadata = {
        customMetadata: {
            'board': board,
        }
    };

    const uploadFile = (e) => {
        e.preventDefault()
        const storageRef = ref(storage, `/files/${videoFile.name}`)
        // Create file metadata to update

        const uploadTask = uploadBytesResumable(storageRef, videoFile);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const pct = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                setPercent(pct);
                console.log("%%", pct)
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    downloadVideoUrl = url;
                    console.log("Whats the url... ", downloadVideoUrl)
                }).finally(() => {
                    if(!file) {
                        submitReviewToBackend()
                    } else {
                        uploadAvatar()
                    }
                });
                updateMetadata(storageRef, newMetadata)
                    .then((metadata) => {
                        // Updated metadata for file is returned in the Promise
                    }).catch((error) => {
                    // Uh-oh, an error occurred!
                });

            }
        );

    }
    const uploadAvatar = () => {
        let fileType = file.name.match(/\.([^.]+)$/)[1];
        fileImageName += "." + fileType;
        const storageImageRef = ref(storage, `/files/${fileImageName}`)
        const uploadTaskImage = uploadBytesResumable(storageImageRef, file.file);
        uploadTaskImage.on(
            "state_changed",
            (snapshot) => {
                const pct = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // update progress
                setPercent(pct);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTaskImage.snapshot.ref).then((url) => {
                    downloadImageUrl = url;
                }).finally(() => {
                    submitReviewToBackend();
                });
                updateMetadata(storageImageRef, newMetadata)
                    .then((metadata) => {
                        // Updated metadata for file is returned in the Promise
                        console.log("meta", metadata)
                    }).catch((error) => {
                    // Uh-oh, an error occurred!
                });
            }
        );
    }

    useEffect(() => {
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
        if(defaultAvatar === "") generateRandomAvatar();
        Mixpanel.track('Testimonial Step 1', mixpanelProps);
    })

    function generateRandomAvatar() {
        setDefaultAvatar(getRandomAvatar);
    }

    function getFile(file){
        setFile(file)
    }

    const handleClose = () => {
        setApiError(!apiError);
    };


    const goToStep2 = () => {
        setStep(2);
    }

    const addWord = (word) => {
        let addedSuggestions = selectedSuggestedWords
        if(!isSelectedSuggestion(word)) {
            let wordsToAppend = words;
            if(words.length)  {
                wordsToAppend += ', '
            }
            wordsToAppend += word;
            setWords(wordsToAppend);
            addedSuggestions.push(word)
            setSelectedSuggestedWords(addedSuggestions)
        }
    }

    const isSelectedSuggestion = (word) => {
        return selectedSuggestedWords.includes(word);
    }

    const handleEditTestimonial = (e) => {
        setEditableTestimonial(e.target.value);
    };

    const handleGetSuggestion = async (e) => {
        if(!name) {
            setShowMissingNameMsg(true);
            return
        }
        setShowMissingNameMsg(false);
        if(!words) {
            setShowMissingWordsMsg(true);
            return
        }

        const mixpanelProps = {
            'profile': board,
            'pageType': "ai_assisted_write_manuscript"
        }
        Mixpanel.track('Click Get Manuscript', mixpanelProps);
        setFetchingSuggestion(true);
        setApiError(false);
        setApiErrorMsg('');
        setShowMissingWordsMsg(false);
        e.preventDefault();
        let queryParams = `?profile=${board}&words=${words}&companyname=${profileName}&name=${name}&lang=${lang}`
        fetch(`${API_URL_PUBLIC}/manuscript-suggestion/${board}/${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                })));
        }).then(({ status, json, ok }) => {
            switch (status) {
                case 400:
                    setApiError(true);
                    console.log(json)
                    setApiErrorMsg(json.error);
                    break;
                case 200:
                    setResponse(json.data?.text);
                    setEditableTestimonial(json.data?.text);
                    break;
                case 500:
                default:
                    setApiError(true);
                    setApiErrorMsg("please wait a while and then try again");
            }
        }).catch(error => {
            setApiError(true);
            setApiErrorMsg("Server error");
        }).finally(() => {
            setFetchingSuggestion(false);
        })

    };
    const handleToggleChange = (isChecked) => {
        setConsentGiven(isChecked);
    };
    const consentText = `Jag godkänner att videon och mitt namn kan användas i marknadsföring. Du kan ta tillbaka ditt samtycke genom att kontakta ${profileName}.`

    if(step === 1) {
        return (
            <>
            <section className="px-6 mt-20 ">
                <div className="mx-auto w-full max-w-xl ">
                    <div className="grid grid-cols-1 gap-6 ">
                        <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <h2 className="mt-8 mb-2 text-2xl font-semibold text-heading md:text-3xl">
                                Spela in en video åt {profileName}
                            </h2>
                            <div className="mb-6">
                                <label htmlFor="name" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                    Ditt namn
                                </label>
                                <input type="text" id="name"
                                       className="mt-2 mb-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                       placeholder="Ditt namn"
                                       required={true}
                                       onChange={(e) => setName(e.target.value)}
                                />
                                {showMissingNameMsg && (
                                    <p className={"text-text  mt-2 text-red-500 font-medium"}>Du behöver fylla ditt namn</p>
                                )}
                            </div>
                            <div className="mb-6">
                                <h2 className="mt-2 mb-4 text-xl font-semibold text-heading md:text-xl">
                                    Vi hjälper dig att ta fram ett manus
                                </h2>
                                <label htmlFor="suggestions" className="block text-left mb-1 text-xs font-bold text-gray-900 dark:text-gray-300">
                                    Välj en eller flera påstående som passar in, eller skriv några ord / korta meningar som du vill lyfta fram hos {profileName}
                                </label>

                                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap',  margin: 'auto', justifyContent: 'space-around', alignItems: 'center'}}>
                                    {suggestions.map((suggestion, idx) => {
                                        return (
                                            <span
                                                key={idx}
                                                onClick={() => addWord(suggestion, idx)}
                                                className={'inline-flex mt-2 items-center rounded-full border-2 border-muted-1 bg-muted-1 px-2 py-1 text-sm font-semibold text-heading shadow-sm cursor-pointer ' + (isSelectedSuggestion(suggestion) ? 'bg-green-400 cursor-not-allowed' : '')}>
                                            {suggestion}
                                            </span>
                                        )

                                    })
                                    }
                                </div>
                                <input type="text" id="words"
                                       className="mt-4  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                       placeholder="Skriv några saker som du är nöjd med"
                                       required={true}
                                       value={words}
                                       onChange={(e) => setWords(e.target.value)}
                                /><p className="block text-left mb-2 mt-2 text-text font-small text-gray-900 dark:text-gray-300">
                                Du kan självklart skriva något samtidigt som du använder snabbvalen.
                            </p>

                                {showMissingWordsMsg && (
                                    <p className={"text-text  mt-2 text-red-500 font-medium"}>Du behöver fylla något som du tycker är bra</p>
                                )}
                                {response ? (
                                    <button
                                        type="button"
                                        onClick={handleGetSuggestion}
                                        disabled={fetchingSuggestion}
                                        className={`mt-6 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-secondary bg-secondary px-4 py-2.5 text-base font-semibold text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent focus:outline-none focus:ring-2 focus:ring-primary/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-secondary disabled:hover:bg-secondary disabled:hover:text-white dark:focus:ring-white/80`}>
                                        {fetchingSuggestion ? "Vi skapar ditt manus, kan ta upp till 30 sekunder..." : "✏️️ Generera ett nytt manus"}
                                    </button>

                                ) : (
                                    <button
                                        type="button"
                                        disabled={fetchingSuggestion}
                                        onClick={handleGetSuggestion}
                                        className={`mt-6 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}>
                                        {fetchingSuggestion ? "Vi skapar ditt manus, kan ta upp till 30 sekunder..." : "📝 Föreslå ett manus åt mig"}
                                    </button>

                                )}

                                {apiError && (
                                    <div className="relative rounded-xl bg-layer-1 p-4 pr-16 mt-4">
                                        <div className="flex items-start space-x-2.5 text-red-400">
                                            <ExclamationIcon className="h-6 w-6 flex-shrink-0" />
                                            <div>
                                                <div className="mt-0.5 text-sm font-semibold">
                                                    Failed to generate suggestion
                                                </div>
                                                <div className="mt-1 text-xs font-medium text-text">
                                                    {apiErrorMsg}<br /><br />
                                                    Please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {fetchingSuggestion && (
                                    <>
                                        <p className={"text-text"}> </p>
                                        <ContentLoader  viewBox="0 0 430 230" >
                                            <rect x="0" y="15" rx="2" ry="2" width="100%" height="80" />
                                        </ContentLoader>
                                    </>
                                )}
                            </div>

                                {(response && !fetchingSuggestion) && (
                                    <>
                                            <textarea id="message" rows="26"
                                                      required={true}
                                                      value={editableTestimonial}
                                                      onChange={handleEditTestimonial}
                                                      className="mt-2 mb-4 block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                                      placeholder="Skriv något om oss ✨"
                                                      onBlur={() => Mixpanel.track('User Edited Suggested Manuscript', mixpanelProps)}
                                            />
                                    </>
                                )}


                            <h2 className="mt-2 mb-4 text-xl font-semibold text-heading md:text-xl">
                                När du är redo så spelar du in din video
                            </h2>

                            <div className="grid place-items-center mt-2 ">
                                <div className="content-center w-full max-w-m px-6 h-96 ">
                                    <DoVideoRecorder push={navigate(1)} setVideoFile={setVideoFile}/>
                                </div>
                            </div>

                            <div className="max-w-md m-auto">
                                <button type="submit" onClick={goToStep2} disabled={!videoFile || !name}
                                        className={`mt-8 w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}
                                >
                                    Skicka in
                                </button>
                                {(!videoFile || !name) && (
                                    <p className={"text-sm  mt-2 text-red-500 font-medium"}>Du behöver ange ditt namn och spela in en video</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            )}
            </>
        );
    } else {
        return (
            <>
            {success ? (
                    <section className="px-6 mt-20 ">
                        <div className="mt-80 text-center max-w-4xl m-auto">
                            <h2 className="text-4xl font-semibold text-heading md:text-5xl">
                                🙏 Tack!
                            </h2>
                            <p className="mt-10 mx-auto mt-4 max-w-3xl text-base text-text sm:mt-6 sm:text-lg ">Vi
                                uppskattar verkligen att du tog dig tid att spela in en video!</p>
                        </div>
                    </section>

                ) : (
            <section className="px-6 mt-20 ">
                <div className="mx-auto w-full max-w-xl ">
                    <div className="grid grid-cols-1 gap-6 ">
                        <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <h2 className="mt-8 text-2xl font-semibold text-heading md:text-3xl">
                                Nästan klar 🙌
                            </h2>
                                <form className="my-10" onSubmit={uploadFile}>
                                    <div className="star-rating mt-5 mb-6">
                                        <label htmlFor="email"
                                               className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Hur bra tyckte du vi var?
                                        </label>
                                        {[...Array(5)].map((star, index) => {
                                            index += 1;
                                            return (
                                                <button
                                                    type="button"
                                                    key={index}
                                                    className={index <= (hover || rating) ? "on " : "off"}
                                                    onClick={() => setRating(index)}
                                                    onMouseEnter={() => setHover(index)}
                                                    onMouseLeave={() => setHover(rating)}
                                                >
                                                    <span className="star text-2xl">&#9733;</span>
                                                </button>
                                            );
                                        })}
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="name" className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Din roll och företag
                                        </label>
                                        <input type="text" id="name"
                                               className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                               placeholder="T.ex. Grundare, Kundreferens"
                                               required={true}
                                               onChange={(e) => setTagline(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="email"
                                               className="block text-left mb-2 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            Din mejl
                                        </label>
                                        <p className="block text-left mb-2 text-xs font-small text-gray-900 dark:text-gray-300">
                                            Så att vi vet vem referensen kommer ifrån.
                                        </p>
                                        <input type="email" id="email"
                                               className="mt-2  block w-full rounded-lg border-1 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/60 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                               placeholder="din@mejl.com" required={true}
                                               onChange={(e) => setEmail(e.target.value)}
                                        />

                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="avatar" className="block text-left mb-3 text-sm font-bold text-gray-900 dark:text-gray-300">
                                            <span onClick={generateRandomAvatar} className="text-primary hover:cursor-pointer">👇Generera en ny bild nedan </span> eller ta en bild på dig själv
                                        </label>

                                        <img
                                            src={file?.base64 || defaultAvatar}
                                            alt="your profile"
                                            className="mr-5 inline-block h-18 w-18 shrink-0 rounded-full"
                                        />

                                        <FileBase64
                                            id="avatar"
                                            multiple={ false }
                                            onDone={ getFile } />

                                    </div>



                                    {apiError ? (
                                        <div
                                            className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
                                            role="alert">
                                            <strong className="font-bold">Ooops! </strong>
                                            <span className="block sm:inline">Vi kunde inte spara din recension,<br/> testa igen om en liten stund.<br/>
                                            <button type="button" onClick={handleClose}
                                                    className={"text-white font-regular px-5 py-2.5 text-center bg-yellow-600 mt-2"}>Försök igen</button> </span>
                                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                                <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                                     xmlns="http://www.w3.org/2000/svg" onClick={handleClose}
                                                     viewBox="0 0 20 20"><title>Close</title><path
                                                    d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            <div className={"mt-4"}>
                                                <ToggleCheckbox  text={consentText} onChange={handleToggleChange}></ToggleCheckbox>
                                            </div>
                                            <button type="submit"
                                                    disabled={!consentGiven || !tagline || !email || !rating}
                                                    className={`w-full inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-lg font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2  focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80`}
                                            >
                                                {percent > 0 && (
                                                    <svg aria-hidden="true"
                                                         className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                         viewBox="0 0 100 101" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor"/>
                                                        <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill"/>
                                                    </svg>
                                                )}
                                                Skicka in
                                            </button>
                                            {(!consentGiven || !tagline || !email || !rating) && (
                                                <p className="mt-2 block text-sm text-red-500 text-text">
                                                    Du behöver fylla i samtliga fält
                                                </p>
                                            )}
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
            </section>
                )}
                </>

        );
    }
}
