import React, {useEffect, useState} from "react";
import {WEB_URL} from "../../vars";
import WidgetWallPage from "../frame_widget_wall";
import WidgetAvatarPage from "../frame_widget_avatar";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


export const ContactBlock = (props) => {
    const {id} = props;


    return (
        <section className="p-4 md:p-10">
            <div
                className="mx-auto grid w-full  md:grid-cols-1 md:place-items-center lg:max-w-5xl"
            >

                <div className="grid gap-y-3  md:gap-y-5">
                    <div>
                        <h3
                            className="mb-2 font-sans text-2xl font-semibold tracking-tight text-heading md:text-3xl"
                        >
                            Saknar du något?
                        </h3>
                        <p className="pb-2 pt-0 text-lg text-text">
                            Känner du att det är något som inte finns, men skulle göra din upplevelse av tjänsten bättre? Då får du gärna skicka ett mail till oss på stefan@kundreferens.se
                        </p>
                    </div>
                    <div>
                        <h3
                            className="mb-2 font-sans text-2xl font-semibold tracking-tight text-heading md:text-3xl"
                        >
                            Saknar du en strategi över hur du kan samla in och använda kund testimonials?
                        </h3>
                        <p className="pb-2 pt-0 text-lg text-text">
                            Det är viktigt att ha en strategi för att samla in och använda kundtestimonials. Här är några steg som du kan följa:

                        </p>
                        <ul className={"list-decimal ml-6"}>
                            <li>
                                Identifiera dina mål med att samla in testimonials. Tänk på vad du hoppas att dina testimonials ska åstadkomma, till exempel att öka försäljningen, öka tilliten hos potentiella kunder eller förbättra ditt varumärke.
                            </li>
                            <li>
                                Välj ut lämpliga kunder att fråga om testimonials. Försök att välja kunder som verkligen har haft en positiv upplevelse av ditt företag och som du tror skulle ge en ärlig och genuint positiv recension.
                            </li>
                            <li>
                                Be om en testimonial på ett vänligt sätt. Du kan till exempel skicka ett email eller ringa upp kunden och fråga om de skulle vara intresserade av att ge en testimonial. Var tydlig med vad du hoppas att testimonialen ska användas för, och erbjud gärna att hjälpa till att skriva den om det behövs.
                            </li>
                            <li>
                                Samla in testimonials på olika sätt. Du kan till exempel be kunder att skriva en recension på din hemsida, ge en video-testimonial eller ge en intervju där du ställer frågor om deras upplevelse av ditt företag.
                            </li>
                        </ul>




                    </div>
                    <div>
                        <h3
                            className="mb-2 font-sans text-2xl font-semibold tracking-tight text-heading md:text-3xl"
                        >
                            Behöver du hjälp?
                        </h3>
                        <p className="pb-2 pt-0 text-lg text-text">
                            Vi erbjuder skräddarsydda tjänster som hjälper dig att komma igång med rätt processer och verktyg anpassade utifrån din verksamhet. Kontakta oss på fredrik.burman@gmail.com
                        </p>
                    </div>

                    <div>
                        <h3
                            className="mb-2 font-sans text-2xl font-semibold tracking-tight text-heading md:text-3xl"
                        >
                            Priser och funktioner
                        </h3>
                        <p className="pb-2 pt-0 text-lg text-text">
                            Vår gratisplan erbjuder dig att samla in 5 kundrecensioner, därefter så behöver du uppgrader för att få tillgång till en premium tjänst. Kontakta oss för att veta mer.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )

}
