import React, {useCallback, useState, setState, useEffect} from "react";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import {Link, useNavigate} from 'react-router-dom';

import {app, auth, storage} from '../../firebase'
import {URL_DASHBOARD} from "../../constants";
import {Mixpanel} from "../../Mixpanel";

export default function ResetPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('bg-layer-1', 'text-text', 'antialiased', 'light');
    }, []);

    const sendResetPassword = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                // ..a
                setRequestSent(true)
                Mixpanel.track("Password reset sent")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    };

    useEffect(() => {
        if (loading) return;
        if (user) navigate(URL_DASHBOARD);
    }, [user, loading, navigate]);

    return (
        <>
            <section className="bg-layer-1 p-6 ">
                <div className="mx-auto w-full max-w-xl ">
                    <div className="flex flex-col justify-center pt-10 pb-14 sm:px-6 lg:px-8">
                        <div className="flex flex-col justify-center sm:mx-auto sm:w-full sm:max-w-md">
                            {/* Logo */}
                            <div className="mx-auto h-9 text-heading">
                                <p className={"text-heading font-black"}>Kundreferens.se</p>
                            </div>

                            <p className="mt-6 text-center text-sm text-text">
                                Har du inte ett konto?{" "}
                                <Link to={"/auth/signup"}
                                    className="font-semibold text-primary hover:text-primary-accent"
                                >
                                   Registrera dig här
                                </Link>
                            </p>
                        </div>

                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                            <div className="bg-layer-2 py-8 px-4 shadow sm:rounded-lg sm:px-10">
                                <h1 className="text-center text-3xl font-semibold text-heading">
                                    Logga in
                                </h1>

                                <div className="mt-6 flex flex-col space-y-4">
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-semibold text-heading"
                                        >
                                            Email
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                                        />
                                    </div>

                                    <div className="flex justify-end">
                                        <Link to={"/auth/login"}
                                            className="text-sm font-semibold text-primary hover:text-primary-accent"
                                        >
                                            Logga in här
                                        </Link>
                                    </div>
                                    {requestSent && (
                                        <div
                                            className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
                                            role="alert">
                                            <span className="font-medium">mejl skickat!</span> Det kan vara så att mejlet har fastnat i ditt spam filter så kolla även där.
                                        </div>
                                    )}

                                    <button
                                        type="submit"
                                        className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-primary-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
                                        onClick={sendResetPassword}
                                    >
                                        Skicka återställningsmejl
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       </>
    );
}
