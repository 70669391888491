import React, { useState } from 'react';

const ToggleCheckbox = ({ text, onChange }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        setIsChecked(!isChecked);
        onChange(!isChecked);
    };

    return (
        <div className="flex items-center space-x-3">
            <input
                id="acceptTos"
                type="checkbox"
                onChange={handleToggle}
                checked={isChecked}
                className="h-5 w-5 rounded border-2 border-text bg-transparent text-primary focus:ring-primary focus:ring-offset-0 checked:focus:ring-text disabled:opacity-30"
            />
            <label
                htmlFor="acceptTos"
                className="block text-sm font-semibold text-heading"
            >
                {text}
            </label>
        </div>
    );
};

export default ToggleCheckbox;
