import React, {useEffect, useState} from 'react';
import "../../index.css";
import {API_URL, WEB_URL} from "../../vars";
import {useNavigate, useLocation} from "react-router-dom";
import {Mixpanel} from "../../Mixpanel";



export const ShareFormBlock = (props) => {
    const {id, profile} = props;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        // Send request to your server to increment page view count
    }, [location]);


    const nameURLSafe = encodeURI(profile?.name.trim()).toString()
    const captureTestimonialURL = WEB_URL + "/write-ai/" + id + "?name=" + nameURLSafe;
    const captureVideoTestimonialURL = WEB_URL + "/record/" + id + "?name=" + nameURLSafe;

    return (
        <>
            <section className="bg-layer-1 p-6 ">
                <div className="mx-auto w-full max-w-xl ">
                    <div className="grid grid-cols-1 gap-6 ">
                        <h2 className="mt-8 text-3xl font-semibold text-heading md:text-3xl">
                            Samla in kundreferenser
                        </h2>
                        <p className={"mt-2"}>Det är möjligt att samla in både text och videoreferenser, nedan hittar du information hur du enklast kommer igång.</p>
                        <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <p className={"mt-2 text-text"}>Nedan hittar du länkarna till dina personliga sidor där du kan samla in kundreferenser via text och video.
                            </p>


                        <a href={captureTestimonialURL}
                           target={"_blank"}
                           rel="noreferrer"
                           onClick={() => Mixpanel.track("Dashboard Click View Capture Testimonial Page", {
                               'profile': id, 'page': 'text'
                           })}
                        >
                            <button
                                className="inline-flex mt-4 mb-2 cursor-pointer items-center justify-center rounded-xl border-2 border-transparent bg-transparent px-4 py-2.5 text-primary font-semibold text-text hover:bg-heading/5 focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text">
                                📝 Öppna Din Text Referenssida
                            </button>
                        </a>
                        <a href={captureVideoTestimonialURL}
                           target={"_blank"}
                           rel="noreferrer"
                           onClick={() => Mixpanel.track("Dashboard Click View Capture Testimonial Page", {
                               'profile': id, 'page': 'video'
                           })}
                        >
                            <button
                                className="inline-flex  mb-4 cursor-pointer items-center justify-center rounded-xl border-2 border-transparent bg-transparent px-4 py-2.5 text-primary font-semibold text-text hover:bg-heading/5 focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text">
                                📽️ Öppna Din Video Referenssida
                            </button>
                        </a>
                        </div>
                            <div className="border-muted-2 border-2 rounded-xl bg-layer-2 p-5 md:px-8 md:py-6">
                            <p className={"mt-4 mb-4 text-text font-medium"}>Här kommer ett förlag på mejl du kan skicka till dina kunder för att be dem att lämna en kundreferens.
                            </p>
                            <p className={"mt-2 text-text "}>
                                Hej [Kundens namn],
                                <br />
                                <br />
                                Jag hoppas att allt går bra med dig. Vi på [Ditt företags namn] är alltid angelägna om att höra från våra kunder och vi skulle verkligen uppskatta om du kunde ta några minuter av din tid för att lämna en kundreferens.
                                <br />

                                <br />
                                Det tar bara några minuter att lämna din feedback och vi har även en AI som kan hjälpa dig att ta fram ett utkast till referensen, vilket gör processen ännu snabbare och enklare för dig. Klicka bara på länken nedan för att komma igång:

                                <br />
                                <br />
                                Skriv en text: {captureTestimonialURL}

                                <br />
                                <br />
                                Med vänliga hälsningar,
                                <br />
                                <br />
                                [Ditt namn] från [Ditt företags namn]
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
