
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCnR7Oq79Po0YlPS26y_xWeh_eojdcGi3A",
    authDomain: "testimonial-72030.firebaseapp.com",
    projectId: "testimonial-72030",
    storageBucket: "testimonial-72030.appspot.com",
    messagingSenderId: "987950671647",
    appId: "1:987950671647:web:5000a37aa325ebd1aaf22a",
    measurementId: "G-X1FSK0CQ3X"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export let analytics;
// eslint-disable-next-line no-restricted-globals
if (location.hostname !== 'localhost') {
    analytics = getAnalytics(app);
}
export const auth = getAuth(app);
export const storage = getStorage(app);

